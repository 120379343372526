import React, { useEffect } from 'react'
import HomePageNativeViewModel from '../../view-models/home-page-native-viewmodel';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Carousel } from 'react-responsive-carousel';
import { motion } from 'framer-motion';
import Skeleton from 'react-loading-skeleton'
import { Routes } from '../../navigation/routes';
import ReactPlayer from 'react-player'
import FullScreenVideoPlayer from '../../components/common/full-screen-video-player';
import { GLOBAL_SEARCH_NO_RESULT_TEXT } from './data';

const HomePageNative = () => {
    const {
        isLoading,
        currentPlaceholder,
        isCarouselVisible,
        scrollContainerRef,
        controls,
        globalSearchTerm,
        handleGlobalSearchTermChange,
        isHomePageSettingsFetching,
        homePageSettings,
        isAcademyProfileListFetching,
        academyProfiles,
        navigate,
        isFeaturedDisciplineFetching,
        featuredDiscipline,
        handlePlayShowcaseVideo,
        navSearchInputRef,
        heroSearchInputRef,
        selectedLoadingText,
        selectedNoResultText,
        isGlobalSearchAcademiesFetching,
        globalSearchAcademies,
        isGlobalSearchDisciplinesFetching,
        globalSearchDisciplines,
        isGlobalSearchBlogsFetching,
        globalSearchBlogs,
    } = HomePageNativeViewModel();

    if(isCarouselVisible) {
        StatusBar.setOverlaysWebView({ overlay: false });
        StatusBar.setBackgroundColor({ color: '#3f1c4b' });
        StatusBar.setStyle({ style: Style.Dark });
    }

    useEffect(() => {
        if (isCarouselVisible) {
            StatusBar.setOverlaysWebView({ overlay: false });
            StatusBar.setBackgroundColor({ color: '#3f1c4b' });
            StatusBar.setStyle({ style: Style.Dark });
        } else {
            StatusBar.setOverlaysWebView({ overlay: false });
            StatusBar.setBackgroundColor({ color: '#F9CA54' });
            StatusBar.setStyle({ style: Style.Light });
        }
    }, [isCarouselVisible]);

    return (
        <div className='h-screen flex flex-col'>
            <div className={`sticky top-0 ${isCarouselVisible ? 'rounded-b-2xl p-4 bg-[#3f1c4b]' : 'p-2 py-3 bg-[#F9CA54]'} transition-all`}>
                <div className='flex justify-between items-center gap-2'>
                    <div className='flex gap-2 items-center'>
                        <img 
                            src='/assets/icons/icon.png'
                            className={`${isCarouselVisible ? 'h-12' : 'h-10'} transition-all duration-300`}
                        />
                        <div className={`${isCarouselVisible ? 'block' : 'hidden'} transition-all duration-300`}>
                            <div className='text-xl font-bold text-white whitespace-nowrap'>Hi there!</div>
                            <div className='text-sm text-white whitespace-nowrap'>Let&apos;s make today productive!</div>
                        </div>
                    </div>
                    <motion.div 
                      initial={{ opacity: 0, height: 0 }}
                      animate={{
                          opacity: isCarouselVisible ? 0 : 1,
                          height: isCarouselVisible ? 0 : 'auto',
                          transition: { duration: 0.3, ease: 'easeInOut' }
                      }}
                      className={`overflow-hidden flex-grow`}
                    >
                        <input 
                            type='text'
                            ref={navSearchInputRef}
                            placeholder={currentPlaceholder}
                            className={`w-full border-0 outline-none text-sm bg-white rounded-xl py-2 px-4 focus:outline-0 focus:border-0 focus:ring-0 ${isCarouselVisible ? 'hidden' : 'block'}`}
                            value={globalSearchTerm}
                            onChange={(e) => handleGlobalSearchTermChange(e.target.value)}
                        />
                    </motion.div>
                    {isCarouselVisible ? (
                      <div onClick={() => navigate(Routes.Login)} className={`cursor-pointer bg-[#60A5FA] border-2 border-[#60A5FA] text-white font-bold py-1 px-4 rounded`}>
                          Login
                      </div>
                    ) : (
                      <img 
                        src='/assets/images/login.png'
                        className='h-10 w-10 cursor-pointer'
                        onClick={() => navigate(Routes.Login)}
                      />
                    )}
                </div>
                <div className='flex items-center justify-center'>
                  <motion.div 
                    className='rounded-xl overflow-hidden'
                    initial={{ opacity: 1, width: '100%', marginTop: '1rem' }}
                    animate={controls}
                    transition={{ duration: 0.3, ease: 'easeInOut' }}
                    style={{ overflow: 'hidden' }}
                  >
                    <Carousel
                        infiniteLoop
                        centerMode={false}
                        showArrows={false}
                        showStatus={false}
                        showIndicators
                        showThumbs={false}
                        useKeyboardArrows
                        autoPlay
                        stopOnHover
                        swipeable
                        dynamicHeight={false}
                        emulateTouch
                        autoFocus
                        thumbWidth={100}
                        selectedItem={0}
                        interval={8000}
                        transitionTime={500}
                        swipeScrollTolerance={5}
                    >
                        {isHomePageSettingsFetching ? (
                            Array.from({ length: 3 }).map((_, i) => (
                                <Skeleton 
                                    key={i}
                                    className='w-full h-48'
                                />
                            ))
                        ) : (
                            homePageSettings?.attributes?.featured?.map((item, i) => (
                                <div key={i} className="h-full flex items-center"> 
                                    <img 
                                        alt={`Featured ${i}`}
                                        src={item?.image?.data?.attributes?.url}
                                        className="w-full object-contain" 
                                    />
                                </div>
                            ))
                            // Array.from({ length: 3 }).map((_, i) => (
                            //     <Skeleton 
                            //         key={i}
                            //         className={`w-full h-48`}
                            //     />
                            // ))
                        )}
                    </Carousel>
                  </motion.div>
                </div>
                <motion.div 
                  initial={{ opacity: 1, height: 'auto' }}
                  animate={{
                      opacity: isCarouselVisible ? 1 : 0,
                      height: isCarouselVisible ? 'auto' : 0,
                      transition: { duration: 0.3, ease: 'easeInOut' }
                  }}
                  className={`${isCarouselVisible ? 'mt-4' : 'mt-0'} overflow-hidden`}
                >
                    <input 
                        type='text'
                        ref={heroSearchInputRef}
                        placeholder={currentPlaceholder}
                        className='w-full border-0 text-md bg-white rounded-xl py-3 px-4 focus:outline-0 focus:border-0 focus:ring-0'
                        value={globalSearchTerm}
                        onChange={(e) => handleGlobalSearchTermChange(e.target.value)}
                    />
                </motion.div>
            </div>

            <div ref={scrollContainerRef} className={`${isCarouselVisible ? '' : ''} py-10 px-4 flex-grow overflow-y-auto ${globalSearchTerm.length > 0 ? 'hidden' : ''}`}>

                <div className='py-2 bg-slate-50 rounded-lg mb-4'>
                    <div className='font-semibold text-gray-400 text-xl text-center'>Search by your interests</div>
                    <hr className='mb-1 mt-2 '/>
                    <div className='flex items-center justify-center flex-wrap gap-4 mt-4'>
                        {isFeaturedDisciplineFetching ? (
                            Array.from({ length: 8 }).map((_, i) => (
                                <Skeleton 
                                    key={i}
                                    className='w-[120px] min-w-[120px] h-[38px] rounded-lg'
                                />
                            ))
                        ):(
                            featuredDiscipline.map((item, i) => (
                                <div key={i} className='py-2 px-4 border-2 border-slate-200 rounded-lg shadow-md text-center text-slate-500 font-semibold cursor-pointer active:scale-95 transition-all'>
                                    {item?.attributes?.name}
                                </div>
                            ))
                        )}
                    </div>
                    <hr className='mb-2 mt-4' />
                </div>

                {/* Academy List */}
                <div className='py-2'>
                    <div className='flex justify-between items-center mb-2' onClick={() => navigate(Routes.academies)}>
                        <div className='font-semibold text-gray-600'>Explore academies near you</div>
                        <img className='h-4' src='/assets/images/arrow-right.png' />
                    </div>
                    <div className='flex gap-2 overflow-x-auto'>
                        {isAcademyProfileListFetching ? (
                            Array.from({ length: 10 }).map((_, i) => (
                                <Skeleton key={i} className='bg-slate-300 h-[180px] w-[240px] min-w-[240px] rounded' />
                            ))
                        ) : (
                            academyProfiles?.map((profile, i) => (
                                <div 
                                    className='w-[280px] min-w-[280px] rounded p-1 shadow-sm border border-slate-200 cursor-pointer'
                                    key={i}
                                    onClick={() => navigate(Routes.academy + `/${profile?.id}`)}
                                >
                                    <div className='relative w-full h-[180px]'>
                                        <img 
                                            src={profile?.attributes?.academy?.data?.attributes?.images?.data ? profile?.attributes?.academy?.data?.attributes?.images?.data[0]?.attributes?.url : ''}
                                            className='w-full h-full object-cover rounded'
                                        />
                                        <div className='absolute bottom-2 right-2 text-xs flex gap-1 items-center bg-green-400 opacity-80 p-1 px-2 rounded-lg font-semibold text-gray-800'>
                                            {profile?.attributes?.academy?.data?.attributes?.rating?.toFixed(1) ?? 0.0}
                                            <img src='/assets/images/star-gray.png' className="h-4 w-4"/>
                                        </div>
                                    </div>
                                    <div className='p-1'>
                                        <div className='font-semibold text-gray-900 truncate'>{profile?.attributes?.publicName}</div>
                                        <div className='text-xs text-slate-500 truncate mt-1'>{profile?.attributes?.academy?.data.attributes?.address}</div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                    <hr className='mb-6 mt-2 '/>
                </div>

                <div className='py-2'>
                    <div className='flex justify-between items-center mb-2'>
                        <div className='font-semibold text-gray-600'>Inspire & Showcase: Discover the Journey</div>
                        {/* <img className='h-4' src='/assets/images/arrow-right.png' /> */}
                    </div>
                    <div className='flex gap-2 overflow-x-auto mt-2'>
                        {isHomePageSettingsFetching ? (
                            Array.from({ length: 6 }).map((_, i) => (
                                <Skeleton 
                                    key={i}
                                    className='w-[170px] min-w-[170px] h-[280px] rounded-lg'
                                />
                            ))
                        ):(
                            homePageSettings?.attributes?.showcaseVideos.map((item, i) => (
                                <div onClick={() => handlePlayShowcaseVideo(item?.video?.data?.attributes?.url)} key={i} className="w-[170px] min-w-[170px] max-w-[170px] overflow-hidden">
                                    <ReactPlayer
                                        url={item?.video?.data?.attributes?.url}
                                        width={170}
                                        height={280}
                                        className='showcase-video-thumbnail'
                                        style={{ borderRadius: '6px', overflow: 'hidden' }}
                                    />
                                    <div className="text-xs text-gray-800 mt-1 px-1 line-clamp-2">{item?.caption}</div>
                                </div>
                            ))
                        )}
                    </div>
                    <hr className='mb-6 mt-2 '/>
                </div>

                {/* Event List */}
                {isHomePageSettingsFetching ? (
                    <Skeleton className='h-40 w-full rounded-lg' />
                ) : homePageSettings?.attributes?.showEvents ? (
                    <div className='py-2'>
                        <div className='flex justify-between items-center mb-2'>
                            <div className='font-semibold text-gray-600'>Join Upcoming Events in Your Area!</div>
                            {/* <img className='h-4' src='/assets/images/arrow-right.png' /> */}
                        </div>
                        <Carousel
                            infiniteLoop
                            centerMode={true}
                            centerSlidePercentage={80}
                            showArrows={true}
                            showStatus={false}
                            showIndicators={false}
                            showThumbs={false}
                            useKeyboardArrows
                            autoPlay
                            stopOnHover
                            swipeable={false}
                            dynamicHeight={false}
                            emulateTouch={false}
                            autoFocus={false}
                            thumbWidth={100}
                            selectedItem={0}
                            interval={12000}
                            transitionTime={300}
                        >
                            {Array.from({ length: 3 }).map((_, i) => (
                                <div key={i} className='w-full min-w-full px-2'>
                                    <img 
                                        src="/assets/images/karate.png" 
                                        className='w-full h-[220px] object-cover rounded'
                                    />
                                    <div className='w-full rounded bg-slate-100 py-1'>
                                        <div className='text-sm font-semibold text-gray-800 mt-1 px-1 line-clamp-2'>Lorem ipsum - {i}</div>
                                        <div className='text-xs text-gray-800 mt-1 px-1 line-clamp-2'>#21/2, Lorem ipsum dolor sit amet</div>
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                        <hr className='mb-6 mt-2 '/>
                    </div>
                ) : null}

                <div className='py-6'>
                    <div className='font-semibold text-gray-600 text-center'>Learn more on what <span className='font-semibold text-4xl text-[#3AB6FC]'>SpArts</span> has to offer</div>
                    <div className='flex gap-2 overflow-x-auto mt-4'>
                        {isHomePageSettingsFetching ? (
                            Array.from({ length: 4 }).map((_, i) => (
                                <Skeleton 
                                    key={i}
                                    className='w-[170px] min-w-[170px] h-[280px] rounded'
                                />
                            ))
                        ):(
                            homePageSettings?.attributes?.preFooter.map((item, i) => (
                                <div key={i} className='w-[170px] min-w-[170px] relative border-4 border-slate-300 rounded' onClick={() => navigate(item?.url)}>
                                    <img 
                                        src={item?.background?.data?.attributes?.url}
                                        className='w-full h-[280px] object-cover rounded'
                                    />
                                    <div className='text-xl font-semibold text-white absolute bottom-2 right-2 text-right w-[80%]'>{item?.label}</div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>

            <div className={`py-10 px-4 flex-grow overflow-y-auto ${globalSearchTerm.length > 0 ? '' : 'hidden'}`}>
                {(isGlobalSearchAcademiesFetching || isGlobalSearchDisciplinesFetching || isGlobalSearchBlogsFetching) ? (
                    <div className='text-gray-400 text-center font-semibold p-6 animate-pulse'>{selectedLoadingText}</div>
                ) : (globalSearchAcademies.length === 0 && globalSearchDisciplines.length === 0 && globalSearchBlogs.length === 0) ? (
                    <div className='text-gray-400 text-center font-semibold p-6 flex flex-col gap-2 items-center justify-center'>
                        <img src='/assets/images/failed.png' className='h-12 w-12 opacity-80'/>
                        <div>{selectedNoResultText}</div>
                    </div>
                ) : (
                    <>
                        {globalSearchAcademies?.length > 0 && (
                            <div className=''>
                                <div className='font-semibold text-gray-600 text-right pr-2'>Academies</div>
                                <hr />
                                {globalSearchAcademies?.map((item, i) => (
                                    <div key={i} onClick={() => navigate(Routes.academy + '/' + item?.id)} className='w-full bg-slate-100 rounded px-2 py-1 mt-1 cursor-pointer flex items-center gap-2'>
                                        <img 
                                            src={item?.attributes?.academy?.data?.attributes?.logo?.data ? item?.attributes?.academy?.data?.attributes?.logo?.data?.attributes?.url : '/assets/images/academy.png'} 
                                            className='w-8 h-8 object-cover rounded'
                                        />
                                        <div className='flex-grow'>
                                            <div className='text-sm font-semibold'>{item?.attributes?.publicName}</div>
                                            <div className='text-xs text-gray-600 line-clamp-1'>{item?.attributes?.academy?.data?.attributes?.address}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        {globalSearchDisciplines.length > 0 && (
                            <div className='mt-4'>
                                <div className='font-semibold text-gray-600 text-right pr-2'>Disciplines</div>
                                <hr />
                                <div className='w-full flex flex-wrap gap-x-2 gap-y-1 p-2 justify-end'>
                                    {globalSearchDisciplines?.map((item, i) => (
                                        <div key={i} className='bg-slate-100 rounded px-2 py-1 cursor-pointer'>
                                            {item?.attributes?.name}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {globalSearchBlogs.length > 0 && (
                            <div className='mt-4'>
                                <div className='font-semibold text-gray-600 text-right pr-2'>Blog posts</div>
                                <hr />
                                {globalSearchBlogs?.map((item, i) => (
                                    <div key={i} onClick={() => navigate(Routes.blog + '/' + item?.id)} className='w-full bg-slate-100 rounded px-2 py-1 mt-1 cursor-pointer flex items-center gap-2'>
                                        <img 
                                            src={item?.attributes?.previewImage?.data?.attributes?.url} 
                                            className='w-32 h-20 object-cover rounded'
                                        />
                                        <div className='flex-grow h-20 flex flex-col justify-start py-1'>
                                            <div className='text-sm font-semibold line-clamp-1'>{item?.attributes?.title}</div>
                                            <div className='text-xs text-gray-600 line-clamp-3 mt-1'>{item?.attributes?.description}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default HomePageNative