import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {IoChevronBack} from 'react-icons/io5';
import logo from '../../../assets/icons/link.svg';
import expand from '../../../assets/icons/expand-icon.png';


import RowAction from '../../components/common/rowAction';
import NotificationViewModel from '../../view-models/notification-viewmodel';
import {getFormattedDate} from '../../../utils/helpers/helpers';
import Layout from '../../components/common/layout';

const NotificationsPage = () => {
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const {isLoading, notificationsData, handleViewClick, isExpanded} =
        NotificationViewModel();

    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1); // Go back to the previous route
    };

    useEffect(() => {
        const handleResize = (): void => {
            if (window.innerWidth < 640) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <Layout isProfileSwitchTabVisible={true}>
            <div className="flex p-2 justify-start flex-col">
                <div className="md:block hidden">
                    {<img src={logo} alt="logo" />}
                </div>

                <div className={`flex gap-8 md:pl-3 md:pt-4 pl-2`}>
                    <IoChevronBack
                        className="text-blue-500 text-3xl mt-3 md:block hidden"
                        onClick={handleBackClick}
                    />

                    <span className="md:text-4xl text-2xl font-light md:mt-1">
                        Notifications
                    </span>
                </div>
            </div>

            {/* <div className="flex items-center justify-center gap-4 pt-4">
                <div className="w-full md:w-10/12 p-2 md:p-0  flex flex-col md:flex-row gap-4"> */}
            <div className="md:px-20 md:py-6 flex flex-col gap-4">
                {notificationsData?.reverse()?.map((item, index) => (
                    <RowAction
                        text={item?.attributes?.title}
                        dates={getFormattedDate(item?.attributes.createdAt)}
                        id={index.toString()}
                        description={item?.attributes.description}
                        file = {item?.attributes?.file?.data}
                    />
                ))}
            </div>
        </Layout>
    );
};

export default NotificationsPage;
