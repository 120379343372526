import React from 'react';
import style from './style.module.css';
import logo from "../../../assets/icons/link.svg"
import FooterStatic from '../../components/footer-static-pages';
import NavbarStatic from '../../components/navbar-static-pages';
import { Helmet } from 'react-helmet-async';
import Footer from '../../components/landing-page-items/footer';
import Navbar from '../../components/landing-page-items/navbar';

const CookiePolicy: React.FC  = () => {
    return (
        <div className={style.container}>
            <Helmet>
                <title>SpArts Cookie Policy | How We Use Cookies on the Student Portal</title>
                <meta name='description' content={`Learn about the SpArts Cookie Policy, detailing how we use cookies to enhance your experience on the Student Portal. Understand our practices for improving attendance tracking, assignments, notifications, and SpArts AI features.`} />
            </Helmet>
            <Navbar />
            <div className="p-4 mt-20">
                <h1 className="text-4xl font-semibold mb-4">Cookie Policy</h1>
                <p>Last updated on July 21, 2023</p>
                <p>
                    This Cookie Policy (“Cookie Policy”) is a part of and
                    incorporated within and is to be read along with the Privacy
                    Policy (“Policy”) and Term of Use. The capitalized terms
                    used in this Cookie Policy, but not defined herein, shall
                    have the meaning given to such terms in the Privacy Policy.
                </p>
                
                <h2>What are cookies and local storage?</h2>
                <p>
                    Cookies are small text files that are placed on your browser
                    or device by websites, apps, online media, and
                    advertisements. There are different types of cookies.
                    Cookies served by the entity that operates the domain you
                    are visiting are called “first party cookies.” So, cookies
                    served by SpArts while you are on the SpArts Platform are
                    first party cookies. Cookies served by companies that are
                    not operating the domain you are visiting are called “third
                    party cookies.” For eg., we may allow Google to set a cookie
                    on your browser while you visit the SpArts Platform, and
                    that would be a third party cookie. Cookies may also endure
                    for different periods of time. “Session Cookies” only last
                    only as long as your browser is open. These are deleted
                    automatically once you close your browser. Other cookies are
                    “persistent cookies” meaning that they survive after your
                    browser is closed. For example, they may recognize your
                    device when you re-open your browser and browse the internet
                    again. “Pixel tags” (also called beacons or pixels) are
                    small blocks of code installed on (or called by) a webpage,
                    app, or advertisement which can retrieve certain information
                    about your device and browser, including for example: device
                    type, operating system, browser type and version, website
                    visited, time of visit, referring website, IP address, and
                    other similar information, including the small text file
                    (the cookie) that uniquely identifies the device. Pixels
                    provide the means by which third parties can set and read
                    browser cookies from a domain that they do not themselves
                    operate and collect information about visitors to that
                    domain, typically with the permission of the domain owner.
                    Local storage is a technology that allows a website or
                    application to store information locally on your device.
                    “Software Development Kits” (also called SDKs) function like
                    pixels and cookies, but operate in the mobile app context
                    where pixels and cookies cannot always function. The primary
                    app developer can install pieces of code (the SDK) from
                    partners in the app, and thereby allow the partner to
                    collect certain information about user interaction with the
                    app and information about the user device and network
                    information.
                </p>
                
                <h2>Why do we use these technologies?</h2>
                <p>
                    We use cookies and other identification technologies for
                    various purposes, including: authenticating users,store
                    information about you (including on your device or in your
                    browser cache) and your use of our Services and the SpArts
                    Platform, remembering user preferences and settings,
                    determining the popularity of content, delivering and
                    measuring the effectiveness of advertising campaigns,
                    analyzing site traffic and trends, and generally
                    understanding the online behaviors and interests of people
                    who interact with our Services.
                </p>
               
                <h2>Do we use Third Party Cookies?</h2>
                <p>
                    We use a number of suppliers that may also set cookies on
                    your device on our behalf when you visit the SpArts Platform
                    to allow them to deliver the services they are providing.
                    When you visit the SpArts Platform you may receive cookies
                    from third party websites or domains. More information about
                    these cookies may be available on the relevant third party's
                    website. <br />
                    Additional Information About Third Party Analytics in use on
                    the SpArts Platform : <br />
                    Facebook Connect. For more information about what Facebook
                    collects when you use Facebook buttons on the SpArts
                    Platform, please see:{' '}
                    <a
                        className="text-blue-500"
                        href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0">
                        Data Policy
                    </a>
                    . <br /> Twitter. For more information about what Twitter
                    collects when you use the SpArts Platform, please see:{' '}
                    <a
                        className="text-blue-500"
                        href="https://twitter.com/en/privacy.">
                        https://twitter.com/en/privacy.
                    </a>{' '}
                    <br />
                    Google Analytics: For more information about Google
                    Analytics cookies, please see Google's help pages and
                    privacy policy: <br />{' '}
                    <a
                        className="text-blue-500"
                        href="https://policies.google.com/privacy">
                        Google's Privacy Policy
                    </a>
                    <br />{' '}
                    <a
                        className="text-blue-500"
                        href="https://support.google.com/analytics/#topic=14090456">
                        {' '}
                        Google Analytics Help pages{' '}
                    </a>
                    <br />
                    Please note that this is not an exhaustive list of all third
                    party analytics providers that we work with.
                </p>
                
                <h2>How can I control Cookies?</h2>
                <p>
                    Most internet browsers are initially set up to automatically
                    accept cookies. You can change the settings to block cookies
                    or to alert you when cookies are being sent to your device.
                </p>
                <p>
                    There are a number of ways to manage cookies. Please refer
                    to your browser instructions or help screen to learn more
                    about how to adjust or modify your browser settings.
                </p>
                
                <p>
                    If you disable the cookies that we use, this may impact your
                    experience while on the SpArts Platform, for example you may
                    not be able to visit certain areas of the SpArts Platform or
                    you may not receive personalised information when you visit
                    the SpArts Platform or you may also be unable to login to
                    services or programs, such as logging into forums or
                    accounts.
                </p>
                
                <p>
                    If you use different devices to view and access the SpArts
                    Platform (e.g. your computer, smartphone, tablet etc) you
                    will need to ensure that each browser on each device is
                    adjusted to suit your cookie preferences.
                </p>
                
                <h2>Changing your Cookie Settings</h2>
                <p>
                    The browser settings for changing your cookies settings are
                    usually found in the 'options' or 'preferences' menu of your
                    internet browser. In order to understand these settings, the
                    following links may behelpful:
                </p>
                <ul className="pl-12">
                    <li>
                        <a
                            className="text-blue-500"
                            href="https://support.microsoft.com/en-us/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d">
                            Cookie settings in Internet Explorer
                        </a>
                    </li>
                    <li>
                        <a
                            className="text-blue-500"
                            href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US">
                            Cookie settings in Firefox
                        </a>
                    </li>
                    <li>
                        <a
                            className="text-blue-500"
                            href="https://support.google.com/chrome/answer/95647">
                            Cookie settings in Chrome
                        </a>
                    </li>
                    <li>
                        <a
                            className="text-blue-500"
                            href="https://support.apple.com/kb/ph21411?locale=en_GB">
                            Cookie settings in Safari
                        </a>
                    </li>
                </ul>

                
                <p>
                    More information. To find out more about cookies, including
                    how to see what cookies have been set and how to manage and
                    delete them, visit{' '}
                    <a
                        className="text-blue-500"
                        href="https://www.aboutcookies.org/">
                        About Cookies
                    </a>{' '}
                    or{' '}
                    <a className="text-blue-500" href="www.allaboutcookies.org">
                        www.allaboutcookies.org
                    </a>
                    .
                </p>
            </div>
            <Footer />
        </div>
    );
};

export default CookiePolicy;
