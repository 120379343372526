import { IoArrowBackCircleOutline } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { IoReload, IoLocationSharp } from "react-icons/io5";
import { FaParking } from "react-icons/fa";
import Son from '../../../../assets/icons/Son.png';
import Daughter from '../../../../assets/icons/Daughter.png';
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { ActivityFinderResponseData } from "../../../../models/activity-finder-requests/activity-finder-response-attributes";
import { Swiper as SwiperClass } from 'swiper';
import { useNavigate } from "react-router-dom";

interface ActivityFinderProps {
    activityFindPage:number, 
    handleReload:()=>void, 
    handleOpenActivityFinder:()=>void, 
    handlePreviousPage:()=>void, 
    handleNextPage:()=>void,
    handleActivityFinderInputChange: (field: string, value: string | number) => void
    handleActivityFinderFormSubmit: () => void
    recommendations: Record<string, {}>
    currentDiscipline:{academies:ActivityFinderResponseData[],discipline:string},
    prevDiscipline:()=>void,
    nextDiscipline:()=>void,
    currentSecondDiscipline:{academies:ActivityFinderResponseData[],discipline:string},
    prevSecondDiscipline:()=>void,
    nextSecondDiscipline: () => void,
    activeFirstSlideIndex: number,
    activeSecondSlideIndex: number,
    handleFirstSlideChange: (swiper:SwiperClass) => void,
    handleSecondSlideChange: (swiper: SwiperClass) => void,
    isLoggedIn : boolean
}


const ActivityFinder = ({ 
    activityFindPage, 
    handleActivityFinderFormSubmit, 
    handleReload, 
    handleOpenActivityFinder, 
    handlePreviousPage, 
    handleNextPage, 
    handleActivityFinderInputChange,
    recommendations, 
    currentDiscipline,
    prevDiscipline,
    nextDiscipline,
    currentSecondDiscipline,
    prevSecondDiscipline,
    nextSecondDiscipline,
    activeFirstSlideIndex,
    activeSecondSlideIndex,
    handleFirstSlideChange,
    handleSecondSlideChange,
    isLoggedIn
}: ActivityFinderProps) => {

    const [firstswiperInstance, setfirstSwiperInstance] = useState<any>(null)
    const [secondswiperInstance, setsecondSwiperInstance] = useState<any>(null)
    const navigate = useNavigate();
    
    return (
        <>
            <div className="h-screen w-screen bg-black bg-opacity-30 backdrop-blur-lg fixed top-0 left-0 z-50"></div>
            <div className='sm:w-[65%] w-full h-screen p-4 flex flex-col bg-white sm:gap-24 fixed top-0 right-0 bg-[url("./assets/icons/Human.png")] bg-cover bg-center z-[51]'>
                <nav className={`flex items-center ${activityFindPage !== 0 ? 'justify-between':'justify-end'}`}>
                    {activityFindPage !== 0 && <div className='flex gap-4'>
                        <span onClick={handlePreviousPage}><IoArrowBackCircleOutline style={{ width: "40px", height: "40px" }} /></span>
                        <span onClick={handleReload}><IoReload style={{ width: "40px", height: "40px" }} /></span>
                    </div>}
                    <span onClick={handleOpenActivityFinder}><IoMdClose style={{ width: "40px", height: "40px" }}/></span>
                </nav>
                
                {activityFindPage === 0 &&
                    <>
                        <div className='h-96'>
                            <p style={{ lineHeight: "1.5" }} className='sm:text-6xl text-5xl text-center'>Which activity is best for your child</p> 
                            <p className='text-center font-bold'>Discover the perfect match in one minute</p>
                        </div>
                        <button onClick={handleNextPage} className='bg-[#F6B40A] h-11 w-56 border-none rounded-3xl text-lg self-center text-white'>Get Started</button>
                    </>
                }
                        
                {activityFindPage === 1 && <>
                    <p className='sm:text-6xl text-5xl font-bold text-center mt-20'>I am looking for academies for my...</p>
                    <div className='flex justify-center sm:gap-28 gap-4 p-12'>
                        <div className='w-52 flex justify-center flex-col'>
                            <img src={Son} />
                            <button 
                                onClick={() => {
                                    handleActivityFinderInputChange("gender", "male");
                                    handleNextPage();
                                }} 
                                className='bg-[#3AB7FC] h-11 sm:w-56 w-40 border-none rounded-3xl text-lg text-white'
                            >
                                Son
                            </button>
                        </div>
                        <div className='w-52'>
                            <img src={Daughter} />
                            <button 
                                className='bg-[#F6B40A] h-11 sm:w-56 w-40 border-none rounded-3xl text-lg text-white'
                                onClick={() => {
                                    handleActivityFinderInputChange("gender", "female");
                                    handleNextPage();
                                }} 
                            >
                                Daughter
                            </button>
                        </div>
                    </div>
                </>}
                        
                {activityFindPage === 2 && <>
                    <p className='text-6xl font-bold text-center sm:p-0 py-20'>He/She is...</p>
                    <div className='flex flex-col gap-12 px-12'>
                        <button 
                            onClick={() => {
                                handleActivityFinderInputChange("age", "14-100");
                                handleNextPage();
                            }} 
                            className='bg-[#3AB7FC] h-11 w-56 sm:ml-[45rem] border-none rounded-3xl text-lg text-white'
                        >
                            14 years and above
                        </button>
                        <button 
                            onClick={() => {
                                handleActivityFinderInputChange("age", "11-13");
                                handleNextPage();
                            }} 
                            className='bg-[#F6B40A] h-11 w-56 sm:ml-[35rem] border-none rounded-3xl text-lg text-white'
                        >
                            11-13 year old
                        </button>
                        <button 
                            onClick={() => {
                                handleActivityFinderInputChange("age", "8-10");
                                handleNextPage();
                            }} 
                            className='bg-[#3AB7FC] h-11 w-56 sm:ml-96 border-none rounded-3xl text-lg text-white'
                        >
                            8-10 year old
                        </button>
                        <button 
                            onClick={() => {
                                handleActivityFinderInputChange("age", "5-7");
                                handleNextPage();
                            }} 
                            className='bg-[#F6B40A] h-11 w-56 sm:ml-56 border-none rounded-3xl text-lg text-white'
                        >
                            5-7 year old
                        </button>
                        <button 
                            onClick={() => {
                                handleActivityFinderInputChange("age", "0-4");
                                handleNextPage();
                            }} 
                            className='bg-[#3AB7FC] h-11 w-56 border-none rounded-3xl text-lg text-white'
                        >
                            below 5 years
                        </button>
                    </div>
                    </>}
                        
                {activityFindPage === 3 && 
                    <>
                        <p className='sm:text-6xl text-5xl font-bold text-center mt-20'>Do you think he/she...</p>
                        <div className='flex flex-col sm:gap-16 px-12 items-center'>
                            <div className='flex flex-col w-56 items-center justify-center'>
                                <button 
                                    onClick={() => {
                                        handleActivityFinderInputChange("fitnessLevel", "Very Fit");
                                        handleNextPage();
                                    }} 
                                    className='bg-[#3AB7FC] h-11 w-56 border-none rounded-3xl text-lg text-white'
                                >
                                    Is very fit
                                </button>
                                <p className='text-center font-[800]'>(Loves challenging physical activities)</p>
                            </div>
                            <div className='flex flex-col w-56 items-center justify-center'>
                                <button 
                                    onClick={() => {
                                        handleActivityFinderInputChange("fitnessLevel", "Average Fit");
                                        handleNextPage();
                                    }} 
                                    className='bg-[#F6B40A] h-11 w-56 border-none rounded-3xl text-lg text-white'
                                >
                                    Has average fitness
                                </button>
                                <p className='text-center font-[800]'>(Enjoys staying active)</p>
                            </div>
                            <div className='flex flex-col w-56 items-center justify-center'>
                                <button 
                                    onClick={() => {
                                        handleActivityFinderInputChange("fitnessLevel", "Needs Improvement");
                                        handleNextPage();
                                    }} 
                                    className='bg-[#3AB7FC] h-11 w-56 border-none rounded-3xl text-lg text-white'
                                >
                                    Could improve fitness
                                </button>
                                <p className='text-center font-[800]'>(Needs encouragement to be active)</p>
                            </div>
                        </div>
                    </>
                }

                {activityFindPage === 4 && 
                    <>
                        <div>
                            <p className='sm:text-6xl text-4xl font-bold text-center sm:p-0 pt-20'>You are looking to improve his/her...</p>
                            <p className='text-center font-bold'>Choose the one that is most important for the child now</p>
                        </div>
                        <div className='flex flex-wrap sm:gap-16 gap-8 justify-center'>
                            <button 
                                onClick={() => {
                                    handleActivityFinderInputChange("improvementArea", "Confidence");
                                    handleNextPage();
                                }} 
                                className='bg-[#3AB7FC] h-11 w-56 border-none rounded-3xl text-lg text-white'
                            >
                                Confidence
                            </button>
                            <button 
                                onClick={() => {
                                    handleActivityFinderInputChange("improvementArea", "Creativity");
                                    handleNextPage();
                                }} 
                                className='bg-[#F6B40A] h-11 w-56 border-none rounded-3xl text-lg text-white'
                            >
                                Creativity
                            </button>
                            <button 
                                onClick={() => {
                                    handleActivityFinderInputChange("improvementArea", "Focus");
                                    handleNextPage();
                                }} 
                                className='bg-[#3AB7FC] h-11 w-56 border-none rounded-3xl text-lg text-white'
                            >
                                Focus
                            </button>
                            <button 
                                onClick={() => {
                                    handleActivityFinderInputChange("improvementArea", "Balance");
                                    handleNextPage();
                                }}
                                className='bg-[#F6B40A] h-11 w-56 border-none rounded-3xl text-lg text-white'
                            >
                                Balance
                            </button>
                            <button 
                                onClick={() => {
                                    handleActivityFinderInputChange("improvementArea", "Fitness");
                                    handleNextPage();
                                }}
                                className='bg-[#3AB7FC] h-11 w-56 border-none rounded-3xl text-lg text-white'
                            >
                                Fitness
                            </button>
                        </div>
                    </>
                }
                
                {activityFindPage === 5 && 
                    <>
                        <form 
                            className='flex flex-col gap-24'
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleActivityFinderFormSubmit();
                                handleNextPage();
                            }}
                        >
                            <p className='sm:text-6xl text-5xl font-bold text-center sm:p-0 pt-20'>Please enter your pincode...</p>
                            <input 
                                type='number' 
                                max={9999999} 
                                className='bg-[#F6B40A] w-3/5 self-center h-11 rounded-full text-white font-bold text-center' 
                                required
                                onChange={(e) => handleActivityFinderInputChange("pincode", e.target.value)}
                            />
                            <button 
                                type='submit' 
                                className='bg-[#3AB7FC] h-11 w-56 border-none rounded-3xl self-center text-lg text-white mt-24'
                            >
                                Submit
                            </button>
                        </form>
                    </>
                }
                    
                {activityFindPage === 6 && 
                    <div className='sm:w-[65%] w-full p-4 h-screen bg-[#3AB7FC] z-20 fixed gap-4 top-0 right-0 flex flex-col'>
                        <div className='flex justify-between'>
                            <div className='flex gap-4 items-center'>
                                <span onClick={handlePreviousPage}><IoArrowBackCircleOutline style={{ width: "40px", height: "40px" }} /></span>
                                <span onClick={handleReload}><IoReload style={{ width: "43px", height: "30px" }}/></span>
                            </div>
                            <span onClick={handleOpenActivityFinder}><IoMdClose style={{ width: "40px", height: "40px" }}/></span>
                        </div>
                        <p className='text-3xl font-bold text-center'>Here you go, the perfect activities for your child</p>
                        <div className='bg-diamond-gradient bg-cover bg-no-repeat w-full h-full bg-opacity-5 self-center overflow-scroll'>
                            <div className='z-30 w-full h-full flex flex-col items-center gap-16'>
                                <div className='flex sm:flex-row flex-col w-full'>
                                    <div className='sm:w-[50%] w-full flex flex-col items-center p-4'>
                                        <div>
                                            {/* <img src={Gymnastics} className='w-24 h-24' /> */}
                                            <p className='text-center text-lg uppercase font-bold'>{ (Object.keys(recommendations))[0]}</p>
                                        </div>
                                        <p className='text-center'>Here are the top 3 {currentDiscipline?.discipline} academies near you</p>
                                        <div className="w-full flex justify-center">
                                            <div className="relative flex flex-col items-center">
                                                {currentDiscipline && (
                                                    <>
                                                        <div className="text-center flex justify-center items-center gap-4">
                                                            <button className="font-bold" onClick={prevDiscipline}>&lt;</button>
                                                            <span className="text-center uppercase">{currentDiscipline?.discipline}</span>
                                                            <button className="font-bold" onClick={nextDiscipline}>&gt;</button>
                                                        </div>
                                                        {currentDiscipline?.academies[activeFirstSlideIndex]?.academy_profile?.isLive ?  
                                                            <a target="_blank" href={`${process.env.REACT_APP_CLIENT_URL}/academy/${currentDiscipline?.academies[activeFirstSlideIndex]?.academy_profile?.id}`} className="text-center text-[#2a21dc] font-bold pt-4 pb-2 uppercase cursor-pointer">{currentDiscipline?.academies[activeFirstSlideIndex]?.publicName ? currentDiscipline?.academies[activeFirstSlideIndex]?.publicName : currentDiscipline?.academies[activeFirstSlideIndex]?.name}</a>
                                                            : <p className="text-center font-bold pt-4 uppercase">{ currentDiscipline?.academies[activeFirstSlideIndex]?.publicName ? currentDiscipline?.academies[activeFirstSlideIndex]?.publicName : currentDiscipline?.academies[activeFirstSlideIndex]?.name}</p>
                                                        }
                                                        {!currentDiscipline?.academies || currentDiscipline.academies.length === 0 ? (
                                                            <div className={`w-full h-64  flex flex-col items-center justify-between p-8 text-center`}>
                                                                <p className="text-3xl">Oops!</p>
                                                                <p>Sorry, we couldn't find nearby academies right now</p>
                                                                <p>If you know any, do let us know!</p>
                                                                <button className="p-4 bg-[#3AB7FC] text-white" onClick={()=>navigate('/contact-us')}>Contact Us</button>
                                                            </div>
                                                            ) :
                                                            (<><Swiper
                                                                // spaceBetween={15}
                                                                slidesPerView={1}
                                                                autoFocus={true}
                                                                // breakpoints={breakpoints}
                                                                freeMode={true}
                                                                loop={true}
                                                                className='sm:w-96 w-80 h-64 p-6 absolute'
                                                                onSlideChange={handleFirstSlideChange}
                                                                onSwiper={setfirstSwiperInstance}
                                                            >
                                                                {currentDiscipline?.academies?.map((acad, index) => {
                                                                    
                                                                    return (
                                                                        <>
                                                                        <SwiperSlide key={index}>
                                                                        <div className={`w-full h-64 bg-black opacity-50 flex items-center justify-between absolute`}>
                                                                                {acad?.images && acad?.images[0]?.url ? (<img src={acad?.images[0]?.url} className="object-cover mx-auto h-[90%] w-[90%]" />) :
                                                                                    (<div className="mx-auto h-full flex items-center justify-center w-[70%] text-center flex-col">
                                                                                        <p className="text-5xl">☹️</p>
                                                                                        <p className="text-white font-extrabold text-3xl">SORRY!</p>
                                                                                        <p className="text-white">We haven't found any images for this academy!</p>
                                                                                    </div>)
                                                                                }
                                                                            <div className="w-full h-full absolute top-0 flex justify-between items-center">
                                                                                <div className='border-y-[20px] border-y-transparent border-r-[30px] border-r-[#F89D37] cursor-pointer' onClick={() => firstswiperInstance.slidePrev()}></div>
                                                                                <div className='border-y-[20px] border-y-transparent border-l-[30px] border-l-[#F89D37] cursor-pointer' onClick={() => firstswiperInstance.slideNext()}></div>
                                                                            </div>
                                                                        </div>
                                                                        </SwiperSlide>
                                                                        </>
                                                                    )
                                                                })}
                                                            </Swiper>
                                                            {currentDiscipline?.academies[activeFirstSlideIndex]?.address && <div className="flex flex-col w-full items-center gap-4">
                                                                <div className="flex gap-4 mt-2">
                                                                    {currentDiscipline?.academies[activeFirstSlideIndex]?.ammenities?.includes('Parking') &&
                                                                        <img src="/assets/images/parking.png" style={{ width: '30px', height: '30px', alignSelf: 'center' }} />}
                                                                    {currentDiscipline?.academies[activeFirstSlideIndex]?.ammenities?.includes('Certified coach') &&
                                                                        <img src="/assets/images/coach.png" style={{ width: '30px', height: '30px', alignSelf: 'center' }} />}
                                                                    {currentDiscipline?.academies[activeFirstSlideIndex]?.ammenities?.includes('Waiting-room') &&
                                                                        <img src="/assets/images/lounge.png" style={{ width: '30px', height: '30px', alignSelf: 'center' }} />}
                                                                    {currentDiscipline?.academies[activeFirstSlideIndex]?.ammenities?.includes('Restroom') &&
                                                                        <img src="/assets/images/toilet.png" style={{ width: '30px', height: '30px', alignSelf: 'center' }}/>}
                                                                    {currentDiscipline?.academies[activeFirstSlideIndex]?.ammenities?.includes('CCTV') &&
                                                                        <img src="/assets/images/cctv.png" style={{ width: '30px', height: '30px', alignSelf: 'center' }}/>}
                                                                    {currentDiscipline?.academies[activeFirstSlideIndex]?.ammenities?.includes('Drinking water') &&
                                                                        <img src="/assets/images/water.png" style={{ width: '30px', height: '30px', alignSelf: 'center' }}/>}
                                                                </div>
                                                                <div className="flex items-center">
                                                                    <IoLocationSharp style={{ color: '#48742c', width: '40px', height: '40px' }} />
                                                                    <p className="text-center font-bold m-0">
                                                                        {currentDiscipline?.academies[activeFirstSlideIndex]?.address}
                                                                    </p>
                                                                </div>
                                                            </div>}
                                                            </>
                                                            )}
                                                    
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sm:w-[50%] w-full flex flex-col items-center p-4'>
                                        <div>
                                            {/* <img src={Music} className='w-24 h-24'/> */}
                                            <p className='text-center text-lg font-bold uppercase'>{ (Object.keys(recommendations))[1]}</p>
                                        </div>
                                        <p className='text-center'>Here are the top 3 {currentSecondDiscipline?.discipline} academies near you</p>
                                        <div className="w-full flex justify-center">
                                            <div className="relative flex flex-col items-center">
                                                {currentSecondDiscipline && (
                                                    <>
                                                        <div className="text-center flex justify-center items-center gap-4">
                                                            <button className="font-bold" onClick={prevSecondDiscipline}>&lt;</button>
                                                            <span className="text-center uppercase">{currentSecondDiscipline?.discipline}</span>
                                                            <button className="font-bold" onClick={nextSecondDiscipline}>&gt;</button>
                                                        </div>
                                                        {currentSecondDiscipline?.academies[activeSecondSlideIndex]?.academy_profile?.isLive ? 
                                                            
                                                            <a target="_blank" href={`${process.env.REACT_APP_CLIENT_URL}/academy/${currentSecondDiscipline?.academies[activeSecondSlideIndex]?.academy_profile?.id}`} className="text-center text-[#2a21dc] font-bold pt-4 pb-2 uppercase cursor-pointer">{currentSecondDiscipline?.academies[activeSecondSlideIndex]?.publicName ? currentSecondDiscipline?.academies[activeSecondSlideIndex]?.publicName : currentSecondDiscipline?.academies[activeSecondSlideIndex]?.name}</a>
                                                            : <p className="text-center font-bold pt-4 uppercase">{ currentSecondDiscipline?.academies[activeSecondSlideIndex]?.publicName ? currentSecondDiscipline?.academies[activeSecondSlideIndex]?.publicName : currentSecondDiscipline?.academies[activeSecondSlideIndex]?.name}</p>
                                                        }
                                                        {!currentSecondDiscipline?.academies || currentSecondDiscipline.academies.length === 0 ? (
                                                            <div className={`w-full h-64 flex flex-col items-center justify-between p-8 text-center`}>
                                                                <p className=" text-3xl">Oops!</p>
                                                                <p>Sorry, we couldn't find nearby academies right now</p>
                                                                <p>If you know any, do let us know!</p>
                                                                <button className="p-4 bg-[#3AB7FC] text-white" onClick={()=>navigate('/contact-us')}>Contact Us</button>
                                                            </div>
                                                        ) :
                                                            (
                                                        <><Swiper
                                                                // spaceBetween={15}
                                                                slidesPerView={1}
                                                                autoFocus={true}
                                                                // breakpoints={breakpoints}
                                                                freeMode={true}
                                                                loop={true}
                                                                className='sm:w-96 w-80 h-64 p-6 absolute'
                                                                onSlideChange={handleSecondSlideChange}
                                                                onSwiper={setsecondSwiperInstance}
                                                            >
                                                            
                                                                    {currentSecondDiscipline?.academies?.map((acad, index) => {
                                                                    return (
                                                                        <SwiperSlide key={index}>
                                                                            <div className={`w-full h-64 bg-black opacity-50 flex items-center justify-between absolute`}>
                                                                                {acad?.images && acad?.images[0]?.url ? (<img src={acad?.images[0]?.url} className="object-cover mx-auto h-[90%] w-[90%]" />) :
                                                                                    (<div className="mx-auto h-full flex items-center justify-center w-[70%] text-center flex-col">
                                                                                        <p className="text-5xl">☹️</p>
                                                                                        <p className="text-white font-extrabold text-3xl">SORRY!</p>
                                                                                        <p className="text-white">We haven't found any images for this academy!</p>
                                                                                    </div>)
                                                                                }
                                                                                <div className="w-full h-full absolute top-0 flex justify-between items-center">
                                                                                  <div
                                                                                        className='border-y-[20px] border-y-transparent border-r-[30px] border-r-[#F89D37] cursor-pointer'
                                                                                        onClick={() => secondswiperInstance.slidePrev()}
                                                                                    ></div>
                                                                                    <div
                                                                                        className='border-y-[20px] border-y-transparent border-l-[30px] border-l-[#F89D37] cursor-pointer'
                                                                                        onClick={() => secondswiperInstance.slideNext()}
                                                                                    ></div>
                                                                                </div>
                                                                                </div>
                                                                            
                                                                            </SwiperSlide>
                                                                    )
                                                                })}
                                                            </Swiper>
                                                                    {currentSecondDiscipline?.academies[activeSecondSlideIndex]?.address && <div className="flex flex-col w-full items-center gap-4">
                                                                        <div className="flex gap-4 mt-2">
                                                                            {currentSecondDiscipline?.academies[activeSecondSlideIndex]?.ammenities?.includes('Parking') &&
                                                                                <FaParking style={{ width: '30px', height: '30px', alignSelf: 'center' }} />}
                                                                            {currentSecondDiscipline?.academies[activeSecondSlideIndex]?.ammenities?.includes('Certified coach') &&
                                                                                <img src="/assets/images/Certified-Coach.png" style={{ width: '30px', height: '30px', alignSelf: 'center' }} />}
                                                                            {currentSecondDiscipline?.academies[activeSecondSlideIndex]?.ammenities?.includes('Waiting-room') &&
                                                                                <img src="/assets/images/waiting-room.png" style={{ width: '30px', height: '30px', alignSelf: 'center' }} />}
                                                                            {currentSecondDiscipline?.academies[activeSecondSlideIndex]?.ammenities?.includes('Restroom') &&
                                                                                <img src="/assets/images/restroom.png" style={{ width: '30px', height: '30px', alignSelf: 'center' }}/>}
                                                                            {currentSecondDiscipline?.academies[activeSecondSlideIndex]?.ammenities?.includes('CCTV') &&
                                                                                <img src="/assets/images/cctv.png" style={{ width: '30px', height: '30px', alignSelf: 'center' }}/>}
                                                                            {currentSecondDiscipline?.academies[activeSecondSlideIndex]?.ammenities?.includes('Drinking water') &&
                                                                                <img src="/assets/images/Drinking-water.png" style={{ width: '30px', height: '30px', alignSelf: 'center' }}/>}
                                                                        </div>
                                                                        
                                                                            <div className="flex items-center">
                                                                            <IoLocationSharp style={{ color: '#48742c', width: '40px', height: '40px' }} />
                                                                            <p className="text-center font-bold m-0">
                                                                                {currentSecondDiscipline?.academies[activeSecondSlideIndex]?.address}
                                                                            </p>
                                                                            </div>
                                                            </div>}
                                                            </>
                                                            )}
                                                    </>
                                                )}
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                <p className='text-[0.7rem] text-center'>These are suggestions based on the child’s age and self declared fitness. If the child has any pre-existing conditions, kindly check with your paediatrician before starting any activity. These suggestions does not mean that the child cannot take up any other sport or art classes apart from the suggestions shown above.
                                    SpArts does not promote early sports or arts specialization in children, so you may retake the test after few months to check new activities for your child.
                                </p>
                                <a href="#explore-academies" onClick={handleOpenActivityFinder} className='bg-[#3AB7FC] w-40 h-12 text-white flex justify-center items-center cursor-pointer'>Explore</a>                               
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default ActivityFinder;