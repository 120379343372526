export enum Routes {
    DefaultPage= '/',
    Login = '/login',
    SignUp = '/sign-up',
    Profile = '/profile',
    SelectStudentProfile = '/profile/select',
    selectClass='/classes',
    class='/class',
    assignments='/assignments',
    evals='evals',
    notifications='/notifications',
    payments='/payments',
    attendance='/attendance',
    settings='/settings',
    fitness='/fitness',
    fitnessTests='/fitness-tests',
    profileClaim='/claim-profile',
    enrolment = '/enrolment-form',
    forgotPassword = '/forgot-password',
    resetPassword = '/reset-password',
    aboutUs = '/about-us',
    contactUs = '/contact-us',
    cookiePolicy = '/cookie-policy',
    privacyPolicy = '/privacy-policy',
    termsAndConditions = '/terms-conditions',
    commingSoon = '/comming-soon',
    feesPayment = '/fees-payment',
    technique = '/technique',
    payment = '/payment',
    paymentStatus = '/payment-status',
    blogs = '/blogs',
    blog = '/blog',
    certifications = '/certifications',
    academies = '/academies',
    academy = '/academy',
    virtualClasses = '/virtual-classes',
    VirtualClassRoom = '/virtual-class-room',
    ShowcaseVideos = '/showcase-videos'
}
