import {useSelector} from 'react-redux';
import {RootState} from '../../utils/redux/store';
import Webcam from 'react-webcam';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import {drawConnectors, drawLandmarks} from '@mediapipe/drawing_utils';
import {Pose, Results} from '@mediapipe/pose';
import * as mediapipePose from '@mediapipe/pose';
// import {stopSpeech, t2s} from '../../utils/ai/t2s';
import {useMutation, useQuery} from 'react-query';
import { ApiResponse_here, FitnessService} from '../../services/fitness-services';
import {
    TechniqueScoresType,
    calculateFlamingoBalanceCount,
    calculateLungeCount,
    calculatePlankTime,
    calculatePushUpCount,
    calculateSquatCount,
} from '../../utils/ai/poseScoreCalc';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Routes} from '../navigation/routes';
import {CameraOptions} from '@mediapipe/camera_utils';
import { StatusBar } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';

type TechniqueModeType =
    | 'Pushup'
    | 'Pause'
    | 'Squats'
    | 'Pause'
    | 'Flamingo'
    | 'Pause'
    | 'Lunges'
    | 'Pause'
    | 'Plank'
    | null;

const FitnessPageViewModel = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const webcamRef = useRef<Webcam>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [techniqueScores, setTechniqueScores] = useState<TechniqueScoresType>(
        {
            Pushup: 0,
            Squats: 0,
            Flamingo: 0.0,
            Lunges: 0,
            Plank: 0.0,
        },
    );
    const [techniqueMode, setTechniqueMode] = useState<string | null>(null);
    const [currentLandMarks, setCurrentLandMarks] = useState<mediapipePose.NormalizedLandmarkList | null>(null);
    const techniqueList: TechniqueModeType[] = [
        'Pushup',
        'Pause',
        'Squats',
        'Pause',
        'Flamingo',
        'Pause',
        'Lunges',
        'Pause',
        'Plank',
    ];
    const [techniqueIndex, setTechniqueIndex] = useState<number | null>(null);
    const [pause, setPause] = useState<boolean>(false);
    const [isDown, setIsDown] = useState<boolean>(false);
    const [countdown, setCountdown] = useState<number | null>(null);
    const [onGoing, setOnGoing] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showWelcomeModal, setShowWelcomeModal] = useState(false);
    const [showInstructions, setShowInstructions] = useState(false);
    const [showOverlay, setShowOverlay] = useState<boolean>(false);
    const RIGHT_KEYPOINTS = [12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32];
    const [techniqueTiming, settechniqueTiming] = useState<number | null>(null);
    const [pauseTiming, setPauseTiming] = useState<number | null>(null);
    const totalPlankTimeRef = useRef(0);
    const [showRes, setShowRes] = useState(false);
    const [showRestTimer, setShowRestTimer] = useState(false);

    const [showDisclaimerModal, setShowDisclaimerModal] = useState(true);
    const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);
    const [showDetailedExcerciseCounter, setShowDetailedExcerciseCounter] = useState(false);

    const [isWebcamReady, setIsWebcamReady] = useState(false);

    const isNative = Capacitor.isNativePlatform();
    const [hasPermission, setHasPermission] = useState(false);

    useEffect(() => {
        if (isNative) {
            const requestPermissions = async () => {
                const permissions = await Camera.requestPermissions({ permissions: ['camera'] });
                if (permissions.camera === 'granted') {
                    setHasPermission(true);
                } else {
                    console.error('Camera permission not granted');
                }
            };
    
            requestPermissions();
        } else {
            setHasPermission(true);
        }

        isNative &&StatusBar.setOverlaysWebView({ overlay: true });

        return () => {
            isNative && StatusBar.setOverlaysWebView({ overlay: false });
        }
    }, []);

    const [showCameraSwitchOptions, setShowCameraSwitchOptions] = useState(false);

    // useEffect(() => {
    //     if (!disclaimerAccepted) return;
        
    //     if (showWelcomeModal) {
    //     //       t2s(
    //     //     'Hello, Welcome to the sparts Exclusive AI module.',
    //     //     1
    //     // );
    //     // t2s(
    //     //     'Our all new sparts Exclusive Exercise Module is designed specifically for students of sparts partner academies!',
    //     //     1
    //     // );
    //     // t2s(
    //     //     'This innovative module offers a unique opportunity for students to practice basic exercises and compete with batchmates and students across sparts.',
    //     //     1
    //     // );
    
    //     // t2s(
    //     //     'Get started today and take your skills to the next level!',
    //     //     1
    //     // );
    //         // t2s(
    //         //     `Hello, Welcome to the sparts Exclusive AI module. Our all new sparts Exclusive Exercise Module is designed specifically for students of sparts partner academies! This innovative module offers a unique opportunity for students to practice basic exercises and compete with batchmates and students across sparts. Get started today and take your skills to the next level!`,
    //         //     1 // Adjust the speed as needed
    //         // );
    //     }
    // }, [showWelcomeModal, disclaimerAccepted]);

    // useEffect(() => {
    //     if (!showWelcomeModal) {
    //         stopSpeech();
    //     }
    // }, [showWelcomeModal]);

    // useEffect(() => {
    //     if (showInstructions) {
    //         t2s(
    //             `Let’s Get Moving!`, 
    //             1, 1.5
    //         );
    //         t2s(
    //             `Follow These Steps:`, 
    //             1
    //         );
    //         t2s(
    //             "First, Ensure your body is positioned correctly. Next, Face towards your left and keep your body in between the two green lines.", 
    //             1
    //         );
    //         t2s(
    //             "How it Works:", 
    //             1, 2
    //         );
    //         t2s(
    //             "• Each technique lasts for 45 seconds, followed by a 20-second break. • You can pause, or, stop the session using the respective buttons.", 
    //             1
    //         );
    //         t2s(
    //             "Techniques Order:", 
    //             1
    //         );
    //         t2s(
    //             "   First Pushups, then Squats or Situps", 
    //             1
    //         );
    //         t2s(
    //             "Let’s crush this workout together! Ready, set, go!", 
    //             1 , 0.5
    //         );
    //     }
    // }, [showInstructions]);

    const handleDisclaimerAccept = () => {
        setDisclaimerAccepted(true);
        setShowDisclaimerModal(false);
        setShowInstructions(true); 
    };

    const handleDisclaimerCancel = () => {
        navigate(-1); // Go back if the disclaimer is not accepted
    };

    const handleWelcomeNext = () => {
        // stopSpeech(); // Stop the TTS when the welcome popup is closed
        setShowWelcomeModal(false);
        setShowInstructions(true); // Show instruction popup after welcome popup
    };

    const handleInstructionCancel = () => {
        // stopSpeech();
        setShowInstructions(false);
    };

    const handleInstructionButtonClick = () => {
        setShowInstructions(true); // Show instruction popup
    };

    const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
    const [deviceId, setDeviceId] = useState<string>('');
    const [rotateCamFeed, setRotateCamFeed] = useState<boolean>(true);

    useEffect(() => {
        const selectedDevice = devices.find((device) => device.deviceId === deviceId);
        if (selectedDevice?.label.toLowerCase().includes('back') || 
            selectedDevice?.label.toLowerCase().includes('rear') || 
            selectedDevice?.label.toLowerCase().includes('behind') ||
            selectedDevice?.label.toLowerCase().includes('environment')
        ) {   
            setRotateCamFeed(false);
        } else {
            setRotateCamFeed(true);
        }
    }, [deviceId]);

    useEffect(() => {
        setDeviceId(devices[0]?.deviceId);
    }, [devices]);

    // Fetch the list of media devices and filter for video inputs (cameras)
    const handleDevices = useCallback((mediaDevices: MediaDeviceInfo[]) => {
        setDevices(mediaDevices.filter(({ kind }) => kind === 'videoinput'));
    }, []);

    useEffect(() => {
        navigator.mediaDevices.enumerateDevices().then(handleDevices);
    }, [handleDevices]);

    const navigate = useNavigate();

    // Add specific times for each exercise
    const exerciseTimings = {
        Pushup: 40,
        Squats: 40,
        Flamingo: 60,
        Pause: 20,
        Lunges: 40,
        Plank: 40,
    };
    const TechniqueTime = 40;
    const PauseTime = 10;
   
    const handelStop = () => {
        // setShowModal(true);
        // setShowOverlay(false);
        const result = window.confirm('Are you sure you want to stop the session? all progress will be lost.');
        if (result) {
            confirmStop();
        }else{
            goHome()
        }
    };

    const confirmStop = () => {
        setTechniqueScores({
            Pushup: 0,
            Squats: 0,
            Flamingo: 0,
            Lunges: 0,
            Plank: 0,
        });
        window.location.reload();
    };

    const cancelStop = () => {
        setShowModal(false);
    };
    const goHome = () => {
        navigate(Routes.SelectStudentProfile);
    };
    const cancelInstructionStop = () => {
        // stopSpeech();
        setShowInstructions(false);
    };

    const displayWelcomeMessage = () => {
        setShowWelcomeModal(false);
        // t2s(
        //     'Hello, Welcome to the SpArts Exclusive AI module.',
        //     1
        // );
        // t2s(
        //     'Our all new SpArts Exclusive Exercise Module is designed specifically for students of SpArts partner academies!',
        //     1
        // );
        // t2s(
        //     'This innovative module offers a unique opportunity for students to practice basic exercises and compete with batchmates and students across SpArts.',
        //     1
        // );
    
        // t2s(
        //     'Get started today and take your skills to the next level!',
        //     1
        // );
    
        setTimeout(() => {
            setShowWelcomeModal(false);
            setShowInstructions(true); // Show instruction popup after welcome popup
        }, 45000); // Adjust the timeout duration as needed
    };

    const displayInstructionStop = () => {
        setShowInstructions(true);
        // t2s(
        //     'Please follow the following instructions for a seemless experience',
        //     1,
        // );
        // t2s(
        //     '1. Ensure your body is positioned correctly, 2. Face towards your left and keep your body inbetween the two green lines.,',
        //     1,
        // );
        // t2s(
        //     '3. For every technique u will get 45 sec with 30 sec break in each technique, 4. Pause or stop the session using respective buttons.',
        //     1,
        // );
        // t2s(
        //     '5. Pause or stop the session using respective buttons.',
        //     1,
        // );
        // t2s(
        //     " 1.pushup  2. Squats/Sit Ups, . Now you are all set click start to begin", 1
        // )
        setTimeout(() => {
            setShowInstructions(false);
        }, 41000); // 45 seconds = 45000 milliseconds
    };

    const startCountdown = () => {
        // stopSpeech()
        setOnGoing(true);
        setPause(false);

        // Announce the start of the technique
        // t2s(
        //     `Face towards your left while you do the excercise,
        //     Up next, ${techniqueIndex ? techniqueList[techniqueIndex + 1] : techniqueList[0]}`,
        //     1,
        // );
        // t2s(`in`, 1)
        setCountdown(3);
        // Wait for 2 seconds before starting the countdown
        setTimeout(() => {
            let count = 3;
            // t2s(`${count}`, 1.5);
    
            // Announce each second of the countdown
            const interval = setInterval(() => {
                // if(count-1 >= 0){
                //     t2s(`${count - 1}`, 1.5);
                // }
                setCountdown(prev => {
                    if (prev === 0) {
                        // t2s(`Go!`, 1.5, 1.5);
                        clearInterval(interval);
                        setCountdown(null);
                        handleStart();
                        return null
                    }
                    return prev !== null || prev=== 1 ? prev - 1 : null;
                });
                count -= 1;
            }, 1000);
        }, 0);
    };

    const drawSlantedLines = (
        context: CanvasRenderingContext2D,
        width: number,
        height: number,
    ) => {
        // Clear the canvas
        // context.clearRect(0, 0, width, height);

        // Set line style
        context.strokeStyle = '#3EF57E';
        context.lineWidth = 2;

        // Calculate points
        const endX1 = width * 0.3;
        const endX2 = width * 0.7;
        const startY = height;
        // const startX1 = 0.1 * width;
        const startX1 = 0;
        const endY = height - height * 0.4;
        // const startX2 = width * 0.9;
        const startX2 = width;

        // Draw left slanted line
        context.beginPath();
        context.moveTo(startX1, startY);
        context.lineTo(endX1, endY);
        context.stroke();

        // Draw right slanted line
        context.beginPath();
        context.moveTo(startX2, startY);
        context.lineTo(endX2, endY);
        context.stroke();
    };

    const onResults = (results: Results) => {
        try {
            if (!videoRef.current || !canvasRef.current) {
                throw new Error('camera or canvas not available');
            }

            const videoWidth = videoRef.current.videoWidth;
            const videoHeight = videoRef.current.videoHeight;

            // if (!webcamRef.current?.video || !canvasRef.current) {
            //     return;
            // }
            // const videoWidth = webcamRef.current.video.videoWidth;
            // const videoHeight = webcamRef.current.video.videoHeight;
            
            canvasRef.current.width = videoWidth;
            canvasRef.current.height = videoHeight;

            const canvasElement = canvasRef.current;
            const canvasCtx = canvasElement.getContext('2d');

            if (!canvasCtx) {
                throw new Error('Could not get canvas context');
            }

            canvasCtx.save();
            canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
            canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height);

            setCurrentLandMarks(results.poseLandmarks);

            drawConnectors(canvasCtx, results.poseLandmarks, mediapipePose.POSE_CONNECTIONS, {
                color: 'rgba(255, 255, 255, 0.2)',
                lineWidth: 4,
            });
            drawLandmarks(canvasCtx, results.poseLandmarks, {
                color: 'rgba(249, 203, 84, 0.6)',
                lineWidth: 2,
                radius: 3,
            });

            drawSlantedLines(canvasCtx, videoWidth, videoHeight);

            canvasCtx.restore();
        } catch (error) {
            console.error("Error processing results:", error);
        }
    };

    // useEffect(() => {
    //     let userPose: Pose | null = null;
    //     let camera: Camera | null = null;

    //     // Function to initialize the Pose model and camera
    //     const loadPoseModel = async () => {
    //         userPose = new Pose({
    //             locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/pose@0.2/${file}`,
    //         });
    //         userPose.setOptions({
    //             minDetectionConfidence: 0.5,
    //             minTrackingConfidence: 0.5,
    //         });
    //         userPose.onResults(onResults);

    //         // Ensure webcamRef is set and video element is available
    //         if (webcamRef.current && webcamRef.current.video) {
    //             camera = new Camera(webcamRef.current.video, {
    //                 onFrame: async () => {
    //                     if (userPose && webcamRef.current && webcamRef.current.video) {
    //                         await userPose.send({ image: webcamRef.current.video });
    //                     }
    //                 },
    //             });
    //             camera.start();
    //         }
    //     };

    //     // Load the pose model and initialize the camera
    //     loadPoseModel();

    //     // Cleanup function for when component unmounts or deviceId changes
    //     return () => {
    //         if (userPose) {
    //             userPose.close(); // Properly close the Pose instance
    //             userPose = null;
    //         }
    //         if (camera) {
    //             camera.stop(); // Properly stop the camera instance
    //             camera = null;
    //         }
    //     };
    // }, [deviceId]);

    // const onResults = (results: Results) => {
    //     try{
    //         if (!webcamRef.current?.video || !canvasRef.current) {
    //             return;
    //         }
    //         const videoWidth = webcamRef.current.video.videoWidth;
    //         const videoHeight = webcamRef.current.video.videoHeight;

    //         canvasRef.current.width = videoWidth;
    //         canvasRef.current.height = videoHeight;

    //         const canvasElement = canvasRef.current;
    //         const canvasCtx = canvasElement.getContext('2d');
    //         if (canvasCtx == null) throw new Error('Could not get context');

    //         canvasCtx.save();
    //         canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);

    //         canvasCtx.drawImage(
    //             results.image,
    //             0,
    //             0,
    //             canvasElement.width,
    //             canvasElement.height,
    //         );

    //         setCurrentLandMarks(results.poseLandmarks);
    //         setLoading(false);

    //         drawConnectors(
    //             canvasCtx,
    //             results.poseLandmarks,
    //             mediapipePose.POSE_CONNECTIONS,
    //             {color: 'white', lineWidth: 2},
    //         );
    //         drawLandmarks(canvasCtx, results.poseLandmarks, {
    //             color: 'red',
    //             lineWidth: 2,
    //             radius: 3,
    //         });

    //         drawSlantedLines(canvasCtx, videoWidth, videoHeight);

    //         canvasCtx.restore();
    //     }
    //     catch{
    //         throw new Error("Model Loading Error try again..");
    //     }
    // };

    // const userPoseRef = useRef<Pose | null>(null);
    // const [poseModel, setPoseModel] = useState<Pose | null>(null);
    
    useEffect(() => {
        let userPose: Pose | null = null;

        const loadPoseModel = async () => {
            if(!isWebcamReady) return;
            setLoading(true);
            try {
                userPose = new Pose({
                    locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/pose@0.2/${file}`,
                });
    
                userPose.setOptions({
                    // maxNumFaces: 1,
                    minDetectionConfidence: 0.5,
                    minTrackingConfidence: 0.5,
                });
    
                userPose.onResults(onResults);

                const initializePose = async () => {
                    try {
                        if (videoRef.current) {
                            const processFrame = async () => {
                                if (videoRef.current) {
                                    await userPose?.send({ image: videoRef.current });
                                    requestAnimationFrame(processFrame);
                                }
                            };
                            requestAnimationFrame(processFrame);
                            setLoading(false);
                        }
                    } catch (error) {
                        if (error instanceof DOMException) {
                            console.error("Error initializing camera:", error.name, error.message);
                        } else {
                            console.error("Unknown error initializing camera:", error);
                        }
                        setLoading(false);
                    }
                };

                initializePose();
            } catch (error) {
                setLoading(false);
                console.error("Error loading the model:", error);
                throw new Error("Bad network? Error loading the model, try again.");
            }
        };
    
        loadPoseModel();

        return () => {
            // if (videoRef.current && videoRef.current.srcObject instanceof MediaStream) {
            //     const mediaStream = videoRef.current.srcObject;
            //     mediaStream.getTracks().forEach(track => track.stop());
            //     videoRef.current.srcObject = null;
            // }
            // if(webcamRef.current && webcamRef.current.video) {
            //     webcamRef.current.video.srcObject = null;
            //     webcamRef.current.video = null;
            // }
            if (userPose) {
                userPose.close();
                userPose = null;
            }
        };
    }, [deviceId, isWebcamReady]);

    const [tehniqueDone, setTehniqueDone] = useState(false);
    
    const handleCountdown = () => {
        return new Promise<void>(resolve => {
            const tecname = typeof techniqueIndex === 'number'
                            ? techniqueList[techniqueIndex + 1]
                            : techniqueList[0];
            if (tecname === 'Pause') {
                // t2s('Take a break of 20 seconds', 1);
                setShowRestTimer(true)
                resolve();
                return null;
            } else {
                setShowRestTimer(false);
                setCountdown(3);
                // t2s( `Up next ${typeof techniqueIndex === 'number' ? techniqueList[techniqueIndex + 1] : techniqueList[0]}`,1);
                setTimeout(() => {
                    let count = 3;
                    // t2s(`${count}`, 1.5);
                    
                    // Announce each second of the countdown
                    const interval = setInterval(() => {
                        // if(count - 1 > 0) {
                        //     t2s(`${count - 1}`, 1.5);
                        // }
                        setCountdown(prev => {
                            if (prev === 1) {
                                clearInterval(interval);
                                setCountdown(null);
                                resolve(); // No need to pass a value
                                return null;
                            }
                            return prev !== null || prev === 1 ? prev - 1 : null;
                        });
                        count -= 1;
                    }, 1000);
                }, 0);
            }
        });
    };

    const handelNext = async () => {
        if (typeof techniqueIndex === 'number') {
            if (techniqueIndex + 1 < techniqueList.length) {
                await handleCountdown();
                setTechniqueMode(techniqueList[techniqueIndex + 1]);
                setTechniqueIndex(techniqueIndex + 1);
                console.log(techniqueMode);
            } else {
                setTehniqueDone(prev => (!prev ? true : false));
            }
            if (techniqueTiming) {
                setPauseTiming(PauseTime);
                settechniqueTiming(null);
            } else if (pauseTiming) {
                const exerciseName = techniqueList[techniqueIndex + 1];
                settechniqueTiming(exerciseName? exerciseTimings[exerciseName] : TechniqueTime);
                setPauseTiming(null);
            }
        }
    };

    useEffect(() => {
        if (tehniqueDone) {
            // t2s('Session Completed', 1);
            handleFitnessReportSubmit();
            setShowRes(true);
            setShowRestTimer(false);
        }
    }, [tehniqueDone]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (techniqueTiming) {
                if (techniqueTiming === 1) {
                    handelNext();
                }
                // console.log({pause: pause});
                if (!pause) {
                    settechniqueTiming(prev => (prev ? prev - 1 : null));
                    if (techniqueIndex == 2 && TechniqueTime <= 30  && techniqueScores['Flamingo'] >= 15) {
                        // t2s('You lost balance 15 times in 30 seconds! Please try again', 1);
                        handelNext();
                    }
                }
            } else if (pauseTiming) {
                if (pauseTiming === 1) {
                    handelNext();
                }
                if (!pause) {
                    setPauseTiming(prev => (prev ? prev - 1 : null));
                }
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [pause, techniqueTiming, pauseTiming]);

    const handleStart = () => {
        // setFormSubmitted(false)
        setPause(false);
        if (typeof techniqueIndex !== 'number') {
            setTechniqueMode(techniqueList[0]);
            setTechniqueIndex(0);
            setPause(false);
            setOnGoing(true);
            settechniqueTiming(exerciseTimings["Pushup"] || TechniqueTime);
        } else {
            if (techniqueList.length > techniqueIndex + 1) {
                setTechniqueMode(techniqueList[techniqueIndex + 1]);
                setTechniqueIndex(techniqueIndex + 1);
            } else {
                setTechniqueMode(null);
                setTechniqueIndex(null);
                setPause(true);
                setOnGoing(false);
            }
        }
    };

    useEffect(() => {
        // setLoading(false);
        if (!pause && onGoing) {
            const checkVisibility = () => {
                if (currentLandMarks) {
                    for (let i = 0; i < RIGHT_KEYPOINTS.length; i++) {
                        const index = RIGHT_KEYPOINTS[i];
                        const landmark = currentLandMarks[index];
                        if (
                            landmark &&
                            landmark.visibility &&
                            landmark.visibility < 0.5
                        ) {
                            return true;
                        }
                    }
                }
                return false;
            };

            const hasImproperVisibility = checkVisibility();

            setShowOverlay(hasImproperVisibility);

            if (
                techniqueIndex !== null &&
                techniqueMode &&
                currentLandMarks &&
                !hasImproperVisibility
            ) {
                if (techniqueMode === 'Pushup') {
                    calculatePushUpCount(
                        currentLandMarks,
                        isDown,
                        setIsDown,
                        setTechniqueScores,
                        techniqueScores.Pushup
                    );
                } else if (techniqueMode === 'Squats') {
                    calculateSquatCount(
                        currentLandMarks,
                        isDown,
                        setIsDown,
                        setTechniqueScores,
                        techniqueScores.Squats
                    );
                    // pass
                }else if (techniqueMode === 'Flamingo') {
                    calculateFlamingoBalanceCount(
                        currentLandMarks,
                        isDown,
                        setIsDown,
                        setTechniqueScores,
                        techniqueScores.Flamingo,
                        techniqueTiming
                    );
                    //pass
                } 
                else if (techniqueMode === 'Lunges') {
                    calculateLungeCount(
                        currentLandMarks,
                        isDown,
                        setIsDown,
                        setTechniqueScores,
                        techniqueScores.Lunges
                    );
                    //pass
                } else if (techniqueMode === 'Plank') {
                    calculatePlankTime(
                        currentLandMarks,
                        isDown,
                        setIsDown,
                        setTechniqueScores,
                        totalPlankTimeRef,
                        techniqueTiming ?? 0
                    );
                    //pass
                }
            }
        }
    }, [
        pause,
        setPause,
        techniqueIndex,
        techniqueMode,
        currentLandMarks,
        isDown,
        setIsDown,
        techniqueScores,
        displayWelcomeMessage,
        displayInstructionStop,
    ]);

    const handelPause = () => {
        setPause(true);
    };
    const handelContinue = () => {
        setPause(false);
        setOnGoing(true);
    };

    const student = useSelector((state: RootState) => state.activeProfile);

    // const {
    //     isSuccess: isAssignmentDetailsFetchSuccess,
    //     isLoading: isAssignmentDetailsFetching,
    //     data: assignmentData,
    // } = useQuery({
    //     queryKey: [""],
    //     queryFn: () =>
    //         FitnessService.instance.postFitnessData(
    //             student.activeStudent.id,
    //             techniqueScores.Pushup,
    //             techniqueScores.Plank,
    //             techniqueScores.Lunges,
    //             techniqueScores.Squats,
    //         ),
    //     refetchOnWindowFocus: false,
    //     enabled: true,
    // });

    const postFitnessReportData = async (data: {
        studentId: number;
        pushup: number;
        plank: number;
        lunge: number;
        squats: number;
    }): Promise<ApiResponse_here | undefined> => {
        const response = await FitnessService.instance.postFitnessData(
            data.studentId,
            data.pushup,
            data.plank,
            data.lunge,
            data.squats,
        );
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: submitReport,
        isLoading: isNewReportLoading,
        isSuccess: isNewReportAdded,
    } = useMutation(postFitnessReportData, {
        onSuccess: data => {
            setTechniqueMode(null);
            setTechniqueIndex(null);
            setPause(true);
            setShowOverlay(false);
            alert('Your report has been submitted successfully');
            setTehniqueDone(false);
            navigate(-1)
        },
        onError: error => {
            alert('Failed to add Report. Please try again!');
            setTechniqueMode(null);
            setTechniqueIndex(null);
            navigate(-1)
            setPause(true);
            setTehniqueDone(false);
        },
    });
    // useEffect(() => {
    //     const canvas = canvasRef.current;
    //     if (canvas) {
    //         const context = canvas.getContext('2d');

    //         // Set canvas dimensions
    //         canvas.width = canvas.offsetWidth;
    //         canvas.height = canvas.offsetHeight;

    //         // Function to draw slanted lines
    //         const drawLines = () => {
    //             const width = canvas.width;
    //             const height = canvas.height;

    //             if (context) {
    //                 // Clear the canvas
    //                 context.clearRect(0, 0, width, height);

    //                 // Set line style
    //                 context.strokeStyle = 'red';
    //                 context.lineWidth = 2;

    //                 // Calculate points
    //                 const startX1 = width * 0.3;
    //                 const startX2 = width * 0.7;
    //                 const startY = height;
    //                 const endX = width / 2;
    //                 const endY = 0;

    //                 // Draw left slanted line
    //                 context.beginPath();
    //                 context.moveTo(startX1, startY);
    //                 context.lineTo(endX, endY);
    //                 context.stroke();

    //                 // Draw right slanted line
    //                 context.beginPath();
    //                 context.moveTo(startX2, startY);
    //                 context.lineTo(endX, endY);
    //                 context.stroke();
    //             }
    //         };

    //         drawLines();
    //     }
    // }, []);

    const handleFitnessReportSubmit = () => {
        submitReport({
            studentId: student.activeStudent.id,
            pushup: techniqueScores.Pushup,
            plank: techniqueScores.Plank,
            lunge: techniqueScores.Lunges,
            squats: techniqueScores.Squats,
        });
    };

    return {
        techniqueScores,
        loading,
        webcamRef,
        canvasRef,
        techniqueMode,
        displayWelcomeMessage,
        displayInstructionStop,
        showModal,
        confirmStop,
        cancelStop,
        showInstructions,
        onGoing,
        startCountdown,
        handelNext,
        pause,
        handelPause,
        handelContinue,
        handelStop,
        techniqueTiming,
        pauseTiming,
        showOverlay,
        countdown,
        cancelInstructionStop,
        showRes,
        goHome,
        showRestTimer,
        deviceId,
        devices,
        setDeviceId,
        videoRef,
        hasPermission,
        handleInstructionButtonClick,
        isNative,
        disclaimerAccepted,
        setShowDisclaimerModal,
        setShowDetailedExcerciseCounter,
        showCameraSwitchOptions,
        setShowCameraSwitchOptions,
        showDetailedExcerciseCounter,
        showDisclaimerModal,
        handleDisclaimerAccept,
        handleDisclaimerCancel,
        setShowInstructions,
        setDisclaimerAccepted,
        isWebcamReady,
        setIsWebcamReady,
        rotateCamFeed,
    };
};

export default FitnessPageViewModel;
