// ErrorPage.jsx
import React from 'react';
import { useRouteError, useNavigate } from 'react-router-dom';
import ErrorPage1 from "../../../../assets/icons/ErrorPage1 copy.jpg"




const ErrorPage = () => {
  const error = useRouteError();
  const navigate = useNavigate();

  const imglist = [
    ErrorPage1
  ]

  function getRandomElement() {
    const randomIndex = Math.floor(Math.random() * imglist.length);
    return imglist[randomIndex];
  }

  const handleBackClick = () => {
    navigate(-1);
  };

  const isErrorWithMessageAndStack = (err: unknown): err is { message: string; stack: string } =>
    typeof err === "object" && err !== null && "message" in err && "stack" in err;

  return (
      <div role="alert">
          <div className="flex flex-col items-center sm:justify-center justify-evenly sm:gap-8 sm:max-h-[100vh] h-[100vh] overflow-hidden">
              <img
                  src={getRandomElement()}
                  className="max-h-[60vh]"
                  alt="error"
              />
              <div className='flex flex-col gap-8 justify-center items-center text-center h-full w-full'>
                  <h1 className="sm:text-6xl sm:font-bold text-4xl font-normal">
                      Oops!
                  </h1>
                  <div className="flex flex-col items-center justify-center gap-2">
                      <div className="font-bold text-slate-700 text-lg">
                          Something went wrong
                      </div>
                      <div className="font-bold text-slate-700 text-lg">
                          Please try again
                      </div>
                  </div>
                  <div
                      className="bg-[#FFB721] text-white rounded-full p-4 w-[160px] cursor-pointer hover:bg-[#FFB721]/80"
                      onClick={handleBackClick}>
                      TRY AGAIN
                  </div>
                  {/* <div className="text-xs text-red-600 mt-4 overflow-auto">
                    {isErrorWithMessageAndStack(error) ? (
                      <>
                        <p><strong>Error Message:</strong> {error.message}</p>
                        <pre><strong>Stack Trace:</strong> {error.stack}</pre>
                      </>
                    ) : (
                      <p>An unexpected error occurred.</p>
                    )}
                  </div> */}
              </div>
          </div>
      </div>
  );
};

export default ErrorPage;
