import React, {useEffect, useRef, useState} from 'react';
import {
    motion,
    useMotionValueEvent,
    useScroll,
    AnimatePresence,
} from 'framer-motion';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../utils/redux/store';
import {useLocation, useNavigate} from 'react-router-dom';
import {AiFillRobot} from 'react-icons/ai';

import backArrow from '../../../../assets/icons/back-arrow.png';
import logo from '../../../../assets/icons/link.svg';
import {Routes} from '../../../navigation/routes';
import NotificationViewModel from '../../../view-models/notification-viewmodel';
import ProfilePageViewModel from '../../../view-models/select-profile-page-view-model';
import bell from "../../../../assets/icons/bellWithoutRed.png"
import { ImHome } from "react-icons/im";

interface LayoutProps {
    children: React.ReactNode;
    isProfileSwitchTabVisible: boolean;
    hideMobileBottomBar?: boolean;
    hideMobileTopBar?: boolean;
    noPadding?: boolean;
}

const Layout = ({children, isProfileSwitchTabVisible, hideMobileBottomBar, noPadding, hideMobileTopBar}: LayoutProps) => {
    const {studentsData, handleSelectStudentProfile} = ProfilePageViewModel();
    const navigate = useNavigate();
    const [isBarHidden, setIsBarHidden] = useState(false);
    const {scrollY} = useScroll();
    const location = useLocation();
    const prevYRef = useRef(0);
    const {notificationsData} = NotificationViewModel();

    const parentProfile = useSelector(
        (state: RootState) => state.activeProfile,
    );

    useMotionValueEvent(scrollY, 'change', y => {
        const diff = y - prevYRef.current;
        if (Math.abs(diff) > 50) {
            setIsBarHidden(diff > 0);
            prevYRef.current = y;
        }
    });

    const [isDesktopBarHidden,setIsDesktopBarHidden] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsDesktopBarHidden(true);
        }, 5000);

        return () => clearTimeout(timer);
    }, []);

    const hideTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    useEffect(() => {
        const handleMouseMove = (event: MouseEvent) => {
            if (event.clientX < 150) {
                // Show the sidebar
                setIsDesktopBarHidden(false);

                // Clear any existing hide timeout
                if (hideTimeoutRef.current) {
                    clearTimeout(hideTimeoutRef.current);
                }

                // Set a timeout to hide the sidebar after 5 seconds
                hideTimeoutRef.current = setTimeout(() => {
                    setIsDesktopBarHidden(true);
                }, 5000);
            }
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            if (hideTimeoutRef.current) {
                clearTimeout(hideTimeoutRef.current);
            }
        };
    }, []);

    const [isMdOrAbove, setIsMdOrAbove] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMdOrAbove(window.innerWidth >= 768); // Assuming 'md' is 768px and above
        };

        // Check on initial render
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="h-auto md:h-[100vh] w-[100vw] overflow-x-hidden">
            <motion.div
                className="md:hidden w-full h-16 fixed top-0 bg-[#F9CA54] flex flex-col items-center shadow-lg z-[1000]"
                // animate={isBarHidden ? 'hidden' : 'visible'}
                // variants={{
                //     hidden: {
                //         y: '-100%',
                //     },
                //     visible: {
                //         y: '0%',
                //     },
                // }}
                // transition={{duration: 0.2}}
                animate={hideMobileTopBar ? 'hidden' : 'visible'}
                variants={{
                    hidden: {
                        y: '-100%',
                    },
                    visible: {
                        y: '0%',
                    },
                }}
                transition={{duration: 0.2}}
            >
                <div className="flex justify-between items-center w-full px-4">
                    <motion.div
                        className={`rounded-full bg-[#F9CA54] h-12 w-12 flex items-center justify-center text-center`}
                        whileHover={{scale: 1.1}} // Scale up on hover
                        whileTap={{scale: 0.9, opacity: 0.8}} // Scale down and reduce opacity on tap
                        transition={{
                            type: 'spring',
                            stiffness: 300,
                            damping: 20,
                        }} // Add spring transition for smoothness
                        onClick={() => navigate(-1)}>
                        <img
                            src={backArrow}
                            alt="back"
                            className="active:scale-105 h-8 w-8"
                        />
                    </motion.div>
                    <img src={logo} alt="logo" className="h-[80%] cursor-pointer" onClick={() => navigate(Routes.DefaultPage)}/>
                    <div
                        className={`rounded-full bg-[#F9CA54] active:bg-opacity-40 active:shadow-[#fcedc6] active:shadow-inner shadow-2xl h-12 w-12 flex items-center justify-center`}>
                        <img
                            src="/assets/images/user.png"
                            alt="profile pic"
                            className="active:scale-105 h-10 w-10 cursor-pointer"
                            onClick={() =>
                                navigate(Routes.SelectStudentProfile)
                            }
                        />
                    </div>
                </div>
            </motion.div>
            <motion.div 
                animate={isDesktopBarHidden ? 'hidden' : 'visible'} 
                variants={{
                    hidden: {
                        x: '-80%',
                    },
                    visible: {
                        x: '0%',
                    },
                }}
                transition={{duration: 0.2}}
                className="hidden md:block w-20 min-w-22 max-w-24 h-full p-2 fixed z-[1000]"
            >
                <div className='w-full h-full bg-[#F9CA54] p-2 gap-8 rounded-md flex flex-col justify-between items-center'>
                    <div className="flex flex-col gap-5 items-center">
                        <div className=" flex items-center text-center cursor-pointer">
                            <ImHome className=" h-10 w-10 text-white" onClick={()=>navigate(Routes.DefaultPage)}/>
                        </div>
                        <div className="pt-10 flex items-center text-center">
                            <img
                                src="/assets/images/user.png"
                                alt="user pic"
                                className="cursor-pointer h-12 w-12"
                                onClick={() =>
                                    navigate(Routes.SelectStudentProfile)
                                }
                            />
                        </div>
                        <div className="max-h-[52vh] overflow-y-scroll">
                            {isProfileSwitchTabVisible &&
                                studentsData.length > 1 &&
                                studentsData?.map(student => (
                                    <div
                                        className="pt-10 flex items-center"
                                        key={student.id}>
                                        {student?.attributes?.photo?.data
                                            ?.attributes?.url ? (
                                            <img
                                                src={
                                                    student?.attributes?.photo?.data
                                                        ?.attributes?.url
                                                }
                                                onClick={() =>
                                                    handleSelectStudentProfile(
                                                        student,
                                                    )
                                                }
                                                title={
                                                    student?.attributes?.firstName
                                                }
                                                alt="user profiles"
                                                className={`rounded-full min-h-12 min-w-12  max-h-12 max-w-12 ${student.id === parentProfile?.activeStudent?.id ? 'border-[5px] border-yellow-300' : ''}`}
                                            />
                                        ) : (
                                            <img
                                                onClick={() =>
                                                    handleSelectStudentProfile(
                                                        student,
                                                    )
                                                }
                                                src="/assets/images/blank.png"
                                                alt="user profiles"
                                                title={
                                                    student?.attributes?.firstName
                                                }
                                                className={`rounded-full min-h-12 min-w-12  max-h-12 max-w-12 ${student.id === parentProfile?.activeStudent?.id ? 'border-[5px] border-yellow-300' : ''}`}
                                            />
                                        )}
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div>
                        <div className="flex flex-col gap-5 ">
                            <div>
                                {notificationsData &&
                                notificationsData?.length > 0 ? (
                                    <img
                                        src={bell}
                                        alt="notificationicon pic"
                                        className=""
                                        onClick={() =>
                                            navigate(Routes.notifications)
                                        }
                                    />
                                ) : (
                                    <img
                                        src={bell}
                                        alt="notificationicon pic"
                                        className=""
                                        onClick={() =>
                                            navigate(Routes.notifications)
                                        }
                                    />
                                )}
                            </div>
                            <div className="pb-10 pt-10">
                                <img
                                    src="/assets/icons/favicon_AI.svg"
                                    alt="Ai icon"
                                    onClick={() => navigate(Routes.fitness)}
                                />
                            </div>

                            <div className="pb-10">
                                <img
                                    src="/assets/images/settings_svgrepo.com.png"
                                    alt="settingsicon pic"
                                    className=""
                                    onClick={() => navigate(Routes.settings)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
            <motion.div 
                className={`${hideMobileTopBar ? 'pt-0' : 'pt-16'} md:pt-0 h-full`}
                animate={isMdOrAbove && isDesktopBarHidden ? 'hidden' : 'visible'}
                variants={{
                    hidden: {
                        paddingLeft: 0
                    },
                    visible: {
                        paddingLeft: isMdOrAbove ? 70 : 0, // No padding change on smaller screens
                    },
                }}
                transition={{duration: 0.2}}
            >
                <motion.div
                    key={parentProfile.activeStudent.id}
                    initial={{y: 10, opacity: 0}}
                    animate={{y: 0, opacity: 1}}
                    exit={{y: -10, opacity: 0}}
                    transition={{duration: 0.2}}
                    className={`md:h-full ${noPadding ? 'p-0' : 'px-2 py-4 pb-[140px] md:pb-4'}`}
                >
                    {children}
                </motion.div>
            </motion.div>
            {(isProfileSwitchTabVisible && studentsData.length > 1) && (
                <motion.div
                    className="md:hidden w-full bg-[#dcdcdc0e] flex flex-row items-center justify-evenly gap-x-7 pt-0 overflow-x-scroll fixed bottom-[69px] pl-4 backdrop-blur-md rounded-lg z-[1000]"
                    animate={isBarHidden ? 'hidden' : 'visible'}
                    variants={{
                        hidden: {
                            y: '95%',
                            opacity: 0.2,
                        },
                        visible: {
                            y: '0%',
                            opacity: 1,
                        },
                    }}>
                    {isProfileSwitchTabVisible &&
                        studentsData.length > 1 &&
                        studentsData?.map(student => (
                            <motion.div
                                className="p-2 flex items-center"
                                key={student.id}>
                                {student?.attributes?.photo?.data?.attributes
                                    ?.url ? (
                                    <motion.img
                                        src={
                                            student?.attributes?.photo?.data
                                                ?.attributes?.url
                                        }
                                        onClick={() =>
                                            handleSelectStudentProfile(student)
                                        }
                                        title={student?.attributes?.firstName}
                                        alt="user profiles"
                                        className={`rounded-full min-h-14 min-w-14  max-h-14 max-w-14 ${student.id === parentProfile?.activeStudent?.id ? 'border-[5px] border-yellow-300' : ''}`}
                                        animate={isBarHidden ? 'hidden' : 'visible'}
                                        variants={{
                                            hidden: {
                                                scale: 0.6,
                                            },
                                            visible: {
                                                scale: 1,
                                            },
                                        }}
                                    />
                                ) : (
                                    <motion.img
                                        onClick={() =>
                                            handleSelectStudentProfile(student)
                                        }
                                        src="/assets/images/blank.png"
                                        alt="user profiles"
                                        title={student?.attributes?.firstName}
                                        className={`rounded-full min-h-14 min-w-14  max-h-14 max-w-14 ${student.id === parentProfile?.activeStudent?.id ? 'border-[5px] border-yellow-300' : ''}`}
                                        animate={isBarHidden ? 'hidden' : 'visible'}
                                        variants={{
                                            hidden: {
                                                scale: 0.6,
                                            },
                                            visible: {
                                                scale: 1,
                                            },
                                        }}
                                    />
                                )}
                            </motion.div>
                        ))}
                </motion.div>
            )}
            <motion.div
                className="md:hidden w-[calc(100%-8px)] h-16 fixed bottom-0 bg-[#F9CA54] flex justify-between p-2 pl-8 pr-8 rounded-xl m-[4px] mt-0 z-[1000]"
                animate={(isBarHidden || hideMobileBottomBar) ? 'hidden' : 'visible'}
                variants={{
                    hidden: {
                        y: '95%',
                    },
                    visible: {
                        y: '0%',
                    },
                }}>
                <div
                    className={`rounded-full bg-[#F9CA54] ${location.pathname === Routes.notifications ? 'bg-opacity-40 shadow-[#fcedc6] shadow-inner' : ''} h-12 w-12 flex items-center justify-center`}
                    onClick={() => navigate(Routes.notifications)}>
                    <img
                        src={bell}
                        alt="notification"
                        className="active:scale-105 h-8 w-8"
                    />
                </div>
                <div
                    className={`rounded-full bg-[#F9CA54] ${location.pathname === Routes.fitness ? 'bg-opacity-40 shadow-[#fcedc6] shadow-inner' : ''} h-12 w-12 flex items-center justify-center`}
                    onClick={() => navigate(Routes.fitness)}>
                    <img
                        src="/assets/icons/favicon_AI.svg"
                        alt="Ai icon"
                    />
                </div>
                <div
                    className={`rounded-full bg-[#F9CA54] ${location.pathname === Routes.settings ? 'bg-opacity-40 shadow-[#fcedc6] shadow-inner' : ''} h-12 w-12 flex items-center justify-center`}
                    onClick={() => navigate(Routes.settings)}>
                    <img
                        src="/assets/images/settings_svgrepo.com.png"
                        alt="settings"
                        className="active:scale-105 h-8 w-8"
                    />
                </div>
            </motion.div>
        </div>
    );
};

export default Layout;
