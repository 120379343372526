import React from 'react';
import styles from "./styles.module.css"
// import phoneImage from '../../../assets/icons/phone image.png';
import logo from "../../../assets/icons/link.svg"
import image from "../../../assets/about-us-image.svg"
import daisy from "../../../assets/icons/daisy.jpeg"
import mayur from "../../../assets/icons/mayur.jpeg"
import Footer from '../../components/landing-page-items/footer';
import FooterStatic from '../../components/footer-static-pages';
import NavbarStatic from '../../components/navbar-static-pages';
import { Helmet } from 'react-helmet-async';
import Navbar from '../../components/landing-page-items/navbar';
const Aboutpage: React.FC = () => {
    return (
        <div className={styles.container}>
            <Helmet>
                <title>About SpArts | Making the next generation fitter</title>
                <meta name='description' content='Discover SpArts! Our mission is to support students growth in sports and creative arts through innovative tools. Learn how we use technology to make the next generation fitter'/>
            </Helmet>
            <Navbar />
            <div className="sm:pl-6 pl-2 flex flex-col gap-4 sm:pt-12">
                <h1 className="text-2xl text-gray-700 mt-20">
                    A Fitter Next Generation through Sports & Creative Arts
                </h1>
                <p>
                    Studies by WHO, UNICEF and others show that Sports and
                    Creative Arts make participants physically and mentally
                    healthy!
                </p>
            </div>
            <div className="flex justify-between sm:flex-row flex-col">
                <div className="sm:w-1/2 h-[70vh] flex flex-col justify-center gap-4 sm:pl-6 pl-2">
                    {' '}
                    <div className="flex flex-col gap-8 sm:mb-8">
                        <h2 className="text-2xl font-medium text-gray-700">
                            What are we trying to do?
                        </h2>
                        <p>
                            We want to solve the following problems being faced
                            by parents while they search for the apt Sports and
                            Creative Arts academies for their kids.
                        </p>
                    </div>
                    <div className={styles.solution}>
                        <span className={styles.icon}>🔍</span>

                        <div>
                            <h3 className="font-medium">
                                Finding the right academy / trainer
                            </h3>
                            <p>
                                Search academies near you with complete details
                                like batch size, batch timings, fees,
                                student-teacher ratio and more.
                            </p>
                        </div>
                    </div>
                    <div className={styles.solution}>
                        <span className={styles.icon}>🖥️</span>
                        <div>
                            <h3 className="font-medium">
                                Know if the trainers are qualified
                            </h3>
                            <p>
                                Check the qualification and experience of
                                trainers in our partner academies before you
                                decide.
                            </p>
                        </div>
                    </div>
                    <div className={styles.solution}>
                        <span className={styles.icon}>📱</span>
                        <div>
                            <h3 className="font-medium">
                                Stay updated on kid's progress
                            </h3>
                            <p>
                                Through our first in the industry initiative of
                                bridging the gap between academies and parents,
                                we will keep you posted on your ward's progress.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="sm:w-1/2 justify-center flex">
                    <img
                        src={image}
                        className="sm:h-[70vh]"
                        alt="Phone showing app interface"
                    />
                </div>
            </div>

            <div className="w-[100vw] h-auto py-10 bg-gradient-to-r from-purple-100 to-purple-400 font-semibold text-3xl flex flex-row justify-center text-center items-center">
                " We aim to solve the problems of parents searching for quality
                sports and creative arts education."
            </div>

            <main className={styles.main}>
                <section className={styles.section}>
                    <div className={styles.solutions}></div>
                </section>

                <div className="bg-white py-8 px-4 sm:px-6 lg:px-8">
                    <div className="text-center mb-8">
                        <h2 className="text-3xl font-extrabold text-gray-900 mb-6">
                            The Team
                        </h2>
                    </div>
                    <div className="flex flex-col sm:flex-row justify-center items-center sm:space-x-8">
                        <div className="flex flex-row items-center mb-8 sm:mb-0">
                            <img
                                className="w-32 h-32 rounded-full mr-4"
                                src={daisy}
                                alt="daisy"
                            />
                            <div>
                                <h3 className="text-xl font-semibold text-gray-900">
                                    Daisy Priya
                                </h3>
                                <p className="text-base text-gray-500 font-medium">
                                    CO-FOUNDER
                                </p>
                                <p className="text-gray-600 mt-2 text-sm">
                                    'With 10+ years in organisations like
                                    Tracxn, Idea Cellular, IBM and NTPC Ltd,
                                    Daisy brings in expertise in building
                                    products and setting up marketing
                                    operations'
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-row items-center mb-8 sm:mb-0">
                            <img
                                className="w-32 h-32 rounded-full mr-4"
                                src={mayur}
                                alt="mayur"
                            />
                            <div>
                                <h3 className="text-xl font-semibold text-gray-900">
                                    Mayur Borsaikia
                                </h3>
                                <p className="text-base text-gray-500 font-medium">
                                    CO-FOUNDER
                                </p>
                                <p className="text-gray-600 mt-2 text-sm">
                                    An alumni of IIM Bangalore and NIT Warangal,
                                    Mayur has worked extensively in 0-1 journeys
                                    of organisations like Spinny, Swiggy, and
                                    Oyo.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};


export default Aboutpage;
