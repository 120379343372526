import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/bundle';
import { Navigation, FreeMode, Autoplay } from 'swiper/modules';
import SlideUtility from '../../../../utils/slider-utils';
import { AcademyData } from '../../../../models/academy/academy-data';

interface FeaturingAcademiesProps {
  academies: AcademyData[] | undefined,
  handleShowMoreDisciplines: (index: number | null) => void,
  showMoreDisciplinesModal: number | null
}

const AcademyCarousel = ({
  academies,
  handleShowMoreDisciplines,
  showMoreDisciplinesModal
}: FeaturingAcademiesProps) => {
  const [data, setData] = useState<AcademyData[]>([]);
  const [swiperInstance, setSwiperInstance] = useState<any>(null);

  const sliderUtility = new SlideUtility(data);

  const breakpoints = {
    768: {
      slidesPerView: 3,
    },
    
    320: {
      slidesPerView: 1,
    },
  };

  const layout = (randomNumber:number) => {
      switch (randomNumber) {
      case 1:
        return 'top-[20vh] w-[90%] h-[40vh]';
      case 2:
            return 'w-[70%] h-[60vh]';
      case 3:
        return 'w-[70%] h-[60vh] left-[30%]';
      case 4:
        return 'w-full h-[40vh] bottom-0';
      default:
        return 'w-full h-[40vh]';
    }
  }
  
  const innerLayout = (randomNumber:number) => {
    switch (randomNumber) {
      case 1:
        return 'sm:w-[50%] w-[90%] sm:bottom-[54%] bottom-[70%] sm:right-[10%]  text-right gap-4';
      case 2:
        return 'sm:w-[70%] w-[100%] sm:left-[30%] left-[40%]  text-right gap-4';
      case 3:
        return 'sm:w-[70%] w-full sm:right-[75%] right-[45%]  text-left gap-4 gap-4';
      case 4:
        return 'sm:w-[70%] w-[100%] top-[50%] sm:right-[25%] gap-4';
      default:
        return 'sm:w-[70%] w-[100%] top-[50%] sm:right-[25%] gap-4';
    }
  }

  const modalLayout = (randomNumber: number) => {
    switch (randomNumber) {
      case 1:
        return 'right-[10%] sm:top-[20px] top-[80px]';
      case 2:
        return 'right-[1rem] top-[10rem]';
      case 3:
        return 'sm:left-[40%] left-[20%]';
      case 4:
        return 'right-[20%] sm:top-80';
      default:
        return 'right-[20%] sm:top-80';
    }
  }
  
  return (
    <div className='carousel flex flex-col items-center h-[85%]'>
      <div className='flex w-full h-[85%]'>
        
<Swiper modules={[Navigation, FreeMode, Autoplay]}
      // spaceBetween={15}
      slidesPerView={1}
      autoFocus={true}
      // breakpoints={breakpoints}
      freeMode={true}
      loop={true}
      className='w-[calc(100vw-5rem)] h-full p-6'
      onSlideChange={sliderUtility.handleSlideChange}
          onSwiper={setSwiperInstance}>
          
          {academies?.map((d: AcademyData, index: number) => {
            if (d && d.attributes && d.attributes.images && d.attributes?.images.data && d.attributes.images.data[0] && d.attributes.images.data[0].attributes) {
              const url = d.attributes.images.data[0].attributes.url;
              const generateNumber = index > 3 ? Math.floor(Math.random() * 4) + 1 : index;
              // const generateNumber=4
              const generatedClasses = layout(generateNumber);
              const generatedInnerClass = innerLayout(generateNumber);
              return (
                <SwiperSlide key={index}>
                  <div className={`relative bg-cover bg-center flex justify-end items-center ${generatedClasses}`} style={{ backgroundImage: `url(${url})`}}>
                    
                {/* Main Content */}
                <div className={`flex flex-col justify-between max-w-[600px] sm:p-8 p-4 bg-opacity-50 backdrop-blur-md relative bg-white ${generatedInnerClass}`}>
                  <h1 className="sm:text-3xl font-bold text-black">{d?.attributes?.publicName?d?.attributes?.publicName:d?.attributes?.name}</h1>
                  <p className="sm:text-lg font-light text-black">
                    {d?.attributes?.selectedDisciplines?.data.slice(0,5).map((discipline, ind) => (
                      <span key={ind}>
                        {discipline?.attributes?.name}
                        {ind < d?.attributes?.selectedDisciplines?.data.length - 1 && ', '}
                      </span>
                    ))}
                    {d?.attributes?.selectedDisciplines?.data.length > 5 && (
                      <span className="text-blue-500 cursor-pointer ml-2" onClick={()=>handleShowMoreDisciplines(index)}>
                        Show More
                      </span>
                    )}
                  </p>
                      {d?.attributes?.academy_profile?.data &&
                        <a
                          href={`${process.env.REACT_APP_CLIENT_URL}/academy/${d?.attributes?.academy_profile?.data?.id}`}
                          target='_blank'
                          className="px-8 sm:py-3 sm:w-48 w-auto self-end bg-black text-white text-lg font-semibold rounded-md cursor-pointer flex justify-center"
                        >
                        Explore
                      </a>}
                </div>
      {showMoreDisciplinesModal===index && (
        <div className="fixed flex items-center justify-center z-50">
          <div className={`bg-white p-6 rounded-md shadow-lg max-w-[230px] w-full fixed flex flex-col ${modalLayout(generateNumber)}`}>
            <p className="text-black">
              {d?.attributes?.selectedDisciplines?.data.map((discipline, ind) => (
                <span key={ind}>
                  {discipline?.attributes?.name}
                  {ind < d?.attributes?.selectedDisciplines?.data.length - 1 && ', '}
                </span>
              ))}
            </p>
            <button
              className="mt-4 px-4 py-2 bg-black text-white rounded-md"
              onClick={()=>handleShowMoreDisciplines(null)}
            >
              Close
            </button>
          </div>
          {/* Background Overlay */}
          <div
            className={`fixed inset-0 opacity-50 `}
            onClick={() => handleShowMoreDisciplines(null)}
          ></div>
        </div>
      )}
                  </div>
                </SwiperSlide>
              )
            }
          })}
       </Swiper>
        </div>
      <div className="flex gap-60">
      <button
        className={`shadow-sm bg-[#F6B40A] text-white w-[3rem] h-[3rem] text-2xl rounded-full flex items-center justify-center`}
        onClick={() => {
          if (swiperInstance) {
            swiperInstance.slidePrev();
          }
        }}
      >
        {'<'}
      </button>
      <button
        className={`shadow-sm bg-[#F6B40A] text-white w-[3rem] h-[3rem] text-2xl rounded-full flex items-center justify-center`}
        onClick={() => {
          if (swiperInstance) {
            swiperInstance.slideNext();
          }
        }}
      >
        {'>'}
      </button>
    </div>
    </div>
  )
}

export default AcademyCarousel;
