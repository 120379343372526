import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { ACADEMY_PROFILE_QUERY, EXISTING_DEMO_BOOKING_FOR_ACADEMY_QUERY } from "../../utils/constants/globals";
import { AcademyProfileService } from "../../services/academy-profile-service";
import { AcademyReviewPostData } from "../../models/academy-profile/academy-review-post-data";
import { AcademyProfileDataWrapper } from "../../models/academy-profile/academy-profile-data-wrapper";
import { useSelector } from "react-redux";
import { RootState } from "../../utils/redux/store";
import { ClassService } from "../../services/class-service";
import { EnquiryPostData } from "../../models/enquiry/enquiry-post-data";
import { EnquiryWrapper } from "../../models/enquiry/enquiry-wrapper";
import { convertTo24Hour } from "../../utils/helpers/helpers";

const AcademyProfilePageViewModel = () => {
    const { academyId } = useParams();
    const queryClient = useQueryClient();
    const user = useSelector((state: RootState) => state.user);
    const profile = useSelector((state: RootState) => state.activeProfile);

    // get academy profile
    const {
        isLoading: isAcademyProfileFetching,
        data: academyProfile,
    } = useQuery({
        queryKey: [ACADEMY_PROFILE_QUERY, academyId],
        queryFn: () => AcademyProfileService.instance.getAcademyProfile(Number(academyId)),
        refetchOnWindowFocus: false,
        enabled: Number(academyId) > 0
    });

    const [visibleReviews, setVisibleReviews] = useState(4);

    const showMoreReviews = () => {
        setVisibleReviews(visibleReviews + 4);
    };

    const showLessReviews = () => {
        setVisibleReviews(4);
    };

    const [userRating, setUserRating] = useState(0);
    const [userReview, setUserReview] = useState('');

    const handleRatingChange = (value: number) => {
        setUserRating(value);
    };

    const handleReviewChange = (value: string) => {
        setUserReview(value);
    };

    const postAcademyReview = async (
        data: AcademyReviewPostData,
    ): Promise<AcademyProfileDataWrapper | undefined> => {
        const response =
            await AcademyProfileService.instance.postAcademyReview(Number(academyId),data);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: addReview,
        isLoading: isReviewPosting,
        isSuccess: isReviewAdded,
    } = useMutation(postAcademyReview, {
        onSuccess: data => {
            queryClient.invalidateQueries(ACADEMY_PROFILE_QUERY)
        },
        onError: error => {
            alert('Failed to submit review. Please try again!');
        },
    });

    const handleAddReview = () => {
        addReview({
            rating: userRating,
            review: userReview,
            timestamp: new Date().toISOString(),
            givenBy: user?.user?.id ?? 0,
        });
    };

    useEffect(() => {
        if(academyProfile?.data?.data?.attributes?.reviewRatings?.find(r => r.givenBy?.data?.id === user?.user?.id)){
            setUserRating(academyProfile?.data?.data?.attributes?.reviewRatings?.find(r => r.givenBy?.data?.id === user?.user?.id)?.rating ?? 0)
            setUserReview(academyProfile?.data?.data?.attributes?.reviewRatings?.find(r => r.givenBy?.data?.id === user?.user?.id)?.review ?? '')
        }
    },[academyProfile?.data?.data])

    const {
        isLoading: isClassFetching,
        data: classesData,
    } = useQuery({
        queryKey: [academyProfile?.data?.data?.attributes?.academy?.data?.id ?? 0],
        queryFn: () => ClassService.instance.getAllClassesOfAcademy(academyProfile?.data?.data?.attributes?.academy?.data?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (academyProfile?.data?.data?.attributes?.academy?.data?.id ?? 0) > 0,
    });

    const availableDisciplines = useMemo(() => {
        // Extract disciplines and flatten them
        const disciplines = classesData?.data?.data?.flatMap(c => c.attributes?.class_discipline);
        
        // Create a Set of discipline objects (using the id as a unique identifier)
        const uniqueDisciplines = [
            ...new Map(
                disciplines?.map(discipline => [discipline?.data?.id, discipline?.data])
            ).values()
        ];
    
        return uniqueDisciplines.length > 0 ? uniqueDisciplines : [];
    }, [classesData?.data?.data]);

    useEffect(() => {
        if (availableDisciplines.length === 1) {
            setDemoBookingFormData(prev => ({
                ...prev,
                disciplineId: availableDisciplines[0]?.id ?? 0
            }));
        }
    }, [availableDisciplines]);

    const availableTimeSlots = useMemo(() => {
        const allTimeSlots = classesData?.data?.data?.flatMap(c => {
            return c?.attributes?.class_timings?.map(t => {
                return {
                    classId: c.id,
                    className: c?.attributes?.class_name,
                    discipline: c?.attributes?.class_discipline,
                    timeSlot: t,
                }
            })
        });

        return allTimeSlots;
    },[classesData?.data?.data])
    
    const {
        isLoading: isExistingDemoBookingFetching,
        data: existingDemoBooking,
    } = useQuery({
        queryKey: [EXISTING_DEMO_BOOKING_FOR_ACADEMY_QUERY, academyProfile?.data?.data?.attributes?.academy?.data?.id ?? 0],
        queryFn: () => AcademyProfileService.instance.getExistingDemoBooking(user?.user?.id ?? 0,academyProfile?.data?.data?.attributes?.academy?.data?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (academyProfile?.data?.data?.attributes?.academy?.data?.id ?? 0) > 0 && (user?.user?.id ?? 0) > 0,
    });

    const [demoBookingFormData, setDemoBookingFormData] = useState({
        classId: 0,
        disciplineId: 0,
        date: '',
        timing: '',
    })

    useEffect(() => {
        setDemoBookingFormData({
            ...demoBookingFormData,
            timing: '',
        })
    },[demoBookingFormData.classId, demoBookingFormData.date])
    
    const handleDemoBookingFormInputChange = (field: string, value: string | number) => {
        setDemoBookingFormData(prev => ({
            ...prev,
            [field]: value
        }))
    }

    const postDemoBooking = async (
        data: EnquiryPostData,
    ): Promise<EnquiryWrapper | undefined> => {
        const response =
            await AcademyProfileService.instance.bookClassDemo(data);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: bookDemo,
        isLoading: isDemoBooking,
    } = useMutation(postDemoBooking, {
        onSuccess: data => {
            queryClient.invalidateQueries(EXISTING_DEMO_BOOKING_FOR_ACADEMY_QUERY)
        },
        onError: error => {
            alert('Failed tobook demo. Please try again!');
        },
    });

    const [demoBookingFormErrors, setDemoBookingFormErrors] = useState('')

    const handleDemoBooking = () => {
        if(demoBookingFormData.date === ''){
            setDemoBookingFormErrors('Please select a date')
            return
        }else if(demoBookingFormData.timing === ''){
            setDemoBookingFormErrors('Please select a time')
            return
        }
        const date = new Date(demoBookingFormData.date);
        const startTiming = demoBookingFormData.timing.split('-')[0].trim(); 
        const { hours: startHour, minutes: startMinute } = convertTo24Hour(startTiming);
        date.setHours(startHour);
        date.setMinutes(startMinute);
        bookDemo({
            classesInterested: [demoBookingFormData.classId],
            academies: [academyProfile?.data?.data?.attributes?.academy?.data?.id ?? 0],
            discipline: classesData?.data?.data?.find(c => c.id === demoBookingFormData.classId)?.attributes?.class_discipline?.data?.attributes?.name ?? '',
            demoDateAndTime: date.toISOString(),
            demoTimeSlot: demoBookingFormData.timing,
            email: user?.user?.email ?? '',
            addedBy: user?.user?.id ?? 0,
            users: academyProfile?.data?.data?.attributes?.academy?.data?.attributes?.users?.data?.map(user => user.id) ?? [],
            parentName: profile?.parentUserDetails?.attributes?.firstName ?? '',
        });
    }

    const navigate = useNavigate();

    const demoBookingFormRef = useRef<HTMLDivElement>(null)

    const handleScrollToDemoForm = () => {
        demoBookingFormRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    const [isDemoFormInView, setIsDemoFormInView] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
        (entries) => {
            // Check if the demo booking form is in view
            entries.forEach((entry) => {
            if (entry.isIntersecting) {
                setIsDemoFormInView(true);
            } else {
                setIsDemoFormInView(false);
            }
            });
        },
        { threshold: 0.1 } // Trigger when 10% of the form is in view
        );

        if (demoBookingFormRef.current) {
            observer.observe(demoBookingFormRef.current);
        }

        return () => {
        if (demoBookingFormRef.current) {
            observer.unobserve(demoBookingFormRef.current);
        }
        };
    }, []);

    const getWeekDaysFromTomorrow = () => {
        const days = [];
        const today = new Date();
    
        // Start from tomorrow
        today.setDate(today.getDate() + 1);
    
        // Array of day names
        const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    
        // Generate day names and corresponding date strings for one week starting from tomorrow
        for (let i = 0; i < 7; i++) {
            const dayName = dayNames[today.getDay()]; // Get day name
            const dateString = today.toISOString().split('T')[0]; // Get date string in 'YYYY-MM-DD' format
            days.push({ dayName, dateString }); // Push both dayName and dateString
            today.setDate(today.getDate() + 1); // Move to the next day
        }
    
        return days;
    };

    const isWithinAWeek = (date: Date) => {
        const today = new Date();
        
        // Get the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
        const todayDayOfWeek = today.getDay();
        
        // Create a date that represents the end of the week (same day next week)
        const nextWeekSameDay = new Date(today);
        nextWeekSameDay.setDate(today.getDate() + 7);
        
        // Check if the date is before the same day next week (strictly less than)
        return date >= today && date < nextWeekSameDay;
    };

    return {
        isLoading: isAcademyProfileFetching || isReviewPosting || isClassFetching || isExistingDemoBookingFetching || isDemoBooking,
        academyProfile: academyProfile?.data?.data,
        classesData: classesData?.data?.data ?? [],
        visibleReviews,
        showMoreReviews,
        showLessReviews,
        userRating,
        handleRatingChange,
        userReview,
        handleReviewChange,
        handleAddReview,
        user,
        demoBookingFormData,
        handleDemoBookingFormInputChange,
        handleDemoBooking,
        demoBookingFormErrors,
        existingDemoBooking: existingDemoBooking?.data?.data ?? [],
        navigate,
        demoBookingFormRef,
        handleScrollToDemoForm,
        isDemoFormInView,
        availableDisciplines,
        availableTimeSlots,
        getWeekDaysFromTomorrow,
        isWithinAWeek,
    }
}

export default AcademyProfilePageViewModel;