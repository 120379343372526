
// import logo  from "../assets/link.svg"

import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import { Routes } from "../../../navigation/routes";
import { motion, AnimatePresence } from "framer-motion";
import { useSelector } from "react-redux";
import { RootState } from "../../../../utils/redux/store";

const NavbarItems = ({ isToggled }: { isToggled: boolean }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state: RootState) => state.user);

    const items = [
      { label: "Home", link:Routes.DefaultPage },
      { label: "Blogs", link:Routes.blogs },
      { label: "Academies", link:Routes.academies},
      { label: "Login", link:Routes.Login },
      { label: "Sign Up", link:Routes.SignUp },
    ];
  
    const navList = {
      visible: {
        opacity: 1,
        transition: {
          delayChildren: 0.2,
          staggerChildren: 0.07
        }
      },
      hidden: {
        opacity: 0,
        transition: {
          staggerChildren: 0.05,
          staggerDirection: -1
        }
      }
    };
  
    const navItem = {
      visible: {
        y: 0,
        opacity: 1,
        transition: {
          y: { stiffness: 1000, velocity: -100 }
        }
      },
      hidden: {
        y: 50,
        opacity: 0,
        transition: {
          y: { stiffness: 1000, velocity: -100 }
        }
      }
    };
  
    return (
      <>
        <motion.ul
          className="w-full p-4"
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={navList}
        >
          {items.map(item => {
            if(location.pathname === item.link) {
              return
            }
            if(item.link === Routes.Login && user?.user?.username) {
              return (
                <motion.li 
                  variants={navItem} 
                  key={item.label} 
                  className='text-white text-[34px] font-semibold cursor-pointer text-center justify-center border-2 border-white rounded-lg py-1 mx-2 flex items-center gap-2 overflow-hidden'
                  onClick={() => navigate(Routes.SelectStudentProfile)}
                >
                    <img 
                        src='/assets/images/user.png'
                        className='h-12'
                    />
                    {user?.user?.username}
                </motion.li>
              )
            }
            if(item.link === Routes.SignUp && user?.user?.username) {
              return
            }
            return (
              <motion.li 
                className="text-white text-[34px] font-semibold cursor-pointer text-center" 
                variants={navItem} 
                key={item.label} 
                onClick={() => navigate(item.link)}
              >
                <p>{item.label}</p>
              </motion.li>
            )
          })}
        </motion.ul>
      </>
    );
  };

const Navbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const user = useSelector((state: RootState) => state.user);
  const location = useLocation();

  const navContainer = {
    visible: {
      //x: 0,
      opacity: 1,
      transition: {
        x: { velocity: 100 },
        duration: 0.3
      }
    },
    hidden: {
      //x: -250,
      opacity: 0,
      transition: {
        x: { velocity: 100 },
        duration: 0.3
      }
    }
  };

  const handleToggleClick = () => {
    setIsNavOpen(prev => !prev);
  }

  const navigate = useNavigate();

  return (
    <>
        <div className="nav bg-[#F6B40AB2] flex justify-between items-center fixed top-0 w-full h-16 sm:h-18 p-2 sm:px-10 z-10 backdrop-blur-lg shadow-md">
            <div className="">
                <img onClick={() => navigate(Routes.DefaultPage)} src="/assets/icons/link.svg" className="h-10 cursor-pointer" alt="link" />
            </div>
            <div className='items-center gap-[42px] hidden md:flex'>
                <div onClick={() => navigate(Routes.DefaultPage)} className='text-xl font-semibold text-slate-50 cursor-pointer hover:text-gray-400 transition-all'>Home</div>
                <div onClick={() => navigate(Routes.blogs)} className='text-xl font-semibold text-slate-50 cursor-pointer hover:text-gray-400 transition-all'>Blogs</div>
                <div onClick={() => navigate(Routes.academies)} className='text-xl font-semibold text-slate-50 cursor-pointer hover:text-gray-400 transition-all'>Academies</div>
            </div>
            <div className='hidden items-center sm:flex cursor-pointer'>
              {user?.user?.username ? (
                  <div 
                    className='font-semibold px-3 py-2 rounded-lg text-white bg-blue-400 flex items-center gap-2'
                    onClick={() => navigate(Routes.SelectStudentProfile)}
                  >
                      <img 
                          src='/assets/images/user.png'
                          className=''
                      />
                      {user?.user?.username}
                  </div>
              ) : (
                  <div className="font-[Manrope] font-bold text-white sm:text-xl text-base leading-5 justify-between align-middle sm:gap-10">
                      <span 
                        onClick={() => navigate(Routes.Login)}
                        className="cursor-pointer"
                      >
                          Login
                      </span>
                  </div>
              )}
            </div>
            
            <div
                className="sm:hidden flex flex-col gap-1.5 px-2"
                onClick={handleToggleClick}
            >
                <div className=" w-8 z-100 border-b-2 border-black "></div>
                <div className=" w-8 z-100 border-b-2 border-black "></div>
                <div className=" w-8 z-100 border-b-2 border-black "></div>
            </div> 
        </div>
        <AnimatePresence>
            {isNavOpen && (
                <motion.div
                    className="w-full h-[calc(100vh-64px)] fixed bg-[#F6B40A] top-[64px] left-0 z-10 flex flex-col items-center justify-center"
                    initial="hidden"
                    animate={isNavOpen ? "visible" : "hidden"}
                    exit="hidden"
                    variants={navContainer}
                >
                    <NavbarItems isToggled={isNavOpen} />
                </motion.div>
            )}
        </AnimatePresence>
    </>
  );
}

export default Navbar


