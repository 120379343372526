import { useQuery } from "react-query";
import { AcademyProfileService } from "../../services/academy-profile-service";
import { ACADEMY_PROFILE_LIST_QUERY, DISCIPLINES_LIST } from "../../utils/constants/globals";
import { useNavigate } from "react-router-dom";
import { Routes } from '../navigation/routes';
import { useEffect, useState } from "react";

type FilterData = {
    disciplines: (string | number)[];
    localities: (string | number)[];
    ratings: (string | number)[];
    amenities: (string | number)[];
};

const AcademyProfileListViewModel = () => {

    const initialFilterData = {
        disciplines: [],
        localities: [],
        ratings: [],
        amenities: [],
    }
    
    const navigate = useNavigate();
    const [searchKeyword, setSearchKeyword] = useState('');
    const [activeFilterData, setActiveFilterData] = useState<FilterData>(initialFilterData);
    const [localities, setLocalities] = useState(["Varthur", "Whitefield", "Harlur", "HSR Layout", "Marathali", "Bellandur"]);
    const [amenities, setAmenities] = useState(["Certified coach","Parking","Waiting-room","Restroom","CCTV","Drinking water"]);
    const [filterQueryString, setFilterQueryString] = useState('');
    const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);

    useEffect(() => {
        const searchQuery = searchKeyword ? `&filters[$or][0][academy][name][$contains]=${searchKeyword}&filters[$or][1][academy][publicName][$contains]=${searchKeyword}&filters[$or][2][academy][selectedDisciplines][name][$contains]=${searchKeyword}&filters[$or][3][academy][address][$contains]=${searchKeyword}` : '';
        const localityQuery = activeFilterData.localities.length > 0 ? activeFilterData.localities.map((locality, i) => `&filters[$or][${i}][academy][address][$contains]=${locality}`).join('&') : '';
        const amenitiesQuery = activeFilterData.amenities.length > 0 ? activeFilterData.amenities.map((amenity, i) => `&filters[$or][${i}][academy][ammenities][$contains]=${amenity}`).join('&') : '';
        const ratingsQuery = activeFilterData.ratings.length > 0 ? activeFilterData.ratings.map((rating, i) => `&filters[$or][${i}][academy][rating][$gte]=${rating}`).join('&') : '';
        const disciplineQuery = activeFilterData.disciplines.length > 0 ? activeFilterData.disciplines.map((discipline, i) => `&filters[$or][${i}][academy][selectedDisciplines][id]=${discipline}`).join('&') : '';
        setFilterQueryString(searchQuery + localityQuery + amenitiesQuery + ratingsQuery + disciplineQuery);
    }, [activeFilterData,searchKeyword]);

    const handleFilterChange = (filter: keyof FilterData, value: string | number) => {
        setActiveFilterData(prev => ({
            ...prev,
            [filter]: prev[filter].includes(value)
                ? prev[filter].filter(item => item !== value) 
                : [...prev[filter], value],
        }));
    };

    const {
        isLoading: isAcademyProfileListFetching,
        data: academyProfiles,
    } = useQuery({
        queryKey: [ACADEMY_PROFILE_LIST_QUERY, filterQueryString],
        queryFn: () => AcademyProfileService.instance.getAcademyProfileList(filterQueryString),
        refetchOnWindowFocus: false,
        enabled: true
    });

    const {
        isLoading: isDisciplinesLoading,
        data: disciplines,
    } = useQuery({
        queryKey: [DISCIPLINES_LIST],
        queryFn: () => AcademyProfileService.instance.getDisciplinesList(),
        refetchOnWindowFocus: false,
        enabled: true
    })

    const [filteredAcademies, setFilteredAcademies] = useState(academyProfiles?.data?.data);

    useEffect(() => {
        if (academyProfiles?.data?.data) {
            setFilteredAcademies(academyProfiles.data.data);
        }
    }, [academyProfiles]);
    

    const handleOpenAcademyProfile = (id: number) => {
        navigate(Routes.academy + `/${id}`, {replace: false});
    }

    const placeholders = ["discipline", "academy name", "locality"];
    const [placeholderIndex, setPlaceholderIndex] = useState(0);
    const [displayedText, setDisplayedText] = useState("");
    const [typingIndex, setTypingIndex] = useState(0);

    useEffect(() => {
        if (typingIndex < placeholders[placeholderIndex].length) {
            const typingTimeout = setTimeout(() => {
                setDisplayedText((prev) => prev + placeholders[placeholderIndex][typingIndex]);
                setTypingIndex(typingIndex + 1);
            }, 100);

            return () => clearTimeout(typingTimeout);
        } else {
            const nextPlaceholderTimeout = setTimeout(() => {
                setTypingIndex(0);
                setDisplayedText("");
                setPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
            }, 2000);

            return () => clearTimeout(nextPlaceholderTimeout);
        }
    }, [typingIndex, placeholderIndex, placeholders]);

    return {
        isAcademyProfileListFetching,
        academyProfiles: academyProfiles?.data?.data || [],
        handleOpenAcademyProfile,
        searchKeyword,
        disciplines: disciplines?.data?.data,
        activeFilterData,
        handleFilterChange,
        setSearchKeyword,
        localities,
        displayedText,
        amenities,
        isFiltersModalOpen,
        setIsFiltersModalOpen,
    }
}

export default AcademyProfileListViewModel