import { Helmet } from "react-helmet-async";
import Navbar from "../../components/landing-page-items/navbar";
import Footer from "../../components/landing-page-items/footer";
import AcademyProfileListViewModel from "../../view-models/academy-profile-list-viewmodel";
import React, { useEffect, useState } from "react";
import { IoLocationSharp } from 'react-icons/io5';
import Skeleton from "react-loading-skeleton";
import { calculateAverageRating } from "../../../utils/helpers/helpers";

const AcademiesListPage = () => {
    const {
        handleOpenAcademyProfile,
        searchKeyword,
        academyProfiles,
        disciplines,
        setSearchKeyword,
        localities,
        isAcademyProfileListFetching,
        displayedText,
        activeFilterData,
        handleFilterChange,
        amenities,
        isFiltersModalOpen,
        setIsFiltersModalOpen,
    } = AcademyProfileListViewModel();

    const filterSection = () => {
        return (
            <>
                <div className="bg-slate-50 rounded-md shadow-lg p-2 w-full">
                    <div className="font-semibold px-2">Localities</div>
                    <hr className="my-2"/>
                    <div className="flex flex-wrap gap-2 text-sm">
                        {localities?.map((locality, index) => (
                            <span 
                                key={index}
                                className={`p-2 rounded-md shadow-inner cursor-pointer ${activeFilterData.localities?.includes(locality?.toLowerCase()) ? 'bg-[#f9ca53] text-white' :'hover:bg-[#f8ecb1]'}`}
                                onClick={() => handleFilterChange('localities', locality?.toLowerCase())}
                            >
                                {locality}
                            </span>
                        ))}
                    </div>
                </div>
                <div className="bg-slate-50 rounded-md shadow-lg p-2 w-full">
                    <div className="font-semibold px-2">Ratings</div>
                    <hr className="my-2"/>
                    <div className="flex flex-wrap gap-2 text-sm">
                        {[5,4,3,2,1]?.map((rating, index) => (
                            <span 
                                key={index}
                                className={`p-2 rounded-md shadow-inner cursor-pointer ${activeFilterData.ratings?.includes(rating) ? 'bg-[#f9ca53] text-white' :'hover:bg-[#f8ecb1]'}`}
                                onClick={() => handleFilterChange('ratings', rating)}
                            >
                                {rating === 5 ? 5 : rating + '+'} 
                            </span>
                        ))}
                    </div>
                </div>
                <div className="bg-slate-50 rounded-md shadow-lg p-2 w-full">
                    <div className="font-semibold px-2">Amenities</div>
                    <hr className="my-2"/>
                    <div className="flex flex-wrap gap-2 text-sm">
                        {amenities?.map((a, index) => (
                            <span 
                                key={index}
                                className={`p-2 rounded-md shadow-inner cursor-pointer ${activeFilterData.amenities?.includes(a) ? 'bg-[#f9ca53] text-white' :'hover:bg-[#f8ecb1]'}`}
                                onClick={() => handleFilterChange('amenities', a)}
                            >
                                {a}
                            </span>
                        ))}
                    </div>
                </div>
                <div className="bg-slate-50 rounded-md shadow-lg p-2 w-full">
                    <div className="font-semibold px-2">Disciplines</div>
                    <hr className="my-2"/>
                    <div className="flex flex-wrap gap-2">
                        {disciplines?.filter(discipline => discipline?.attributes?.parentDiscipline?.data === null)?.map((discipline, index) => (
                            <React.Fragment key={index}>
                                <span 
                                    className={`p-2 rounded-md text-sm shadow-inner cursor-pointer ${activeFilterData.disciplines.includes(discipline?.id) ? 'bg-[#f9ca53] text-white' :'hover:bg-[#f8ecb1]'}`}
                                    onClick={() => handleFilterChange('disciplines',discipline?.id)}
                                >
                                    {discipline?.attributes?.name}
                                </span>
                                {(discipline?.attributes?.childDisciplines?.data?.length > 0 && activeFilterData.disciplines.includes(discipline?.id)) && 
                                    discipline?.attributes?.childDisciplines?.data?.map((childDiscipline, index) => (
                                        <span 
                                            key={childDiscipline?.id}
                                            className={`p-2 rounded-md text-sm shadow-inner cursor-pointer ${activeFilterData.disciplines.includes(childDiscipline?.id) ? 'bg-[#f9ca53] text-white' :'hover:bg-[#f8ecb1]'}`}
                                            onClick={() => handleFilterChange('disciplines',childDiscipline?.id)}
                                        >
                                            {childDiscipline?.attributes?.name}
                                        </span>
                                    ))
                                }
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className='h-screen flex flex-col'>
            <Helmet>
                <title>SpArts Academies | Discover Sports & Arts Programs for Students</title>
                <meta name="description" content={`Find top-rated sports and arts academies with SpArts. Explore programs designed to support student growth through structured training, skill-building exercises, and SpArts AI for personalized development.`} />
            </Helmet>
            <Navbar />
            <div className='flex-grow flex gap-4 p-2 md:px-4 mt-[66px] relative'>
                <div className="w-[340px] min-w-[340px] flex-shrink-0 bg-slate-200 rounded p-2 max-h-[calc(100vh-88px)] overflow-y-auto hidden md:flex flex-col gap-2 sticky top-[80px] shadow-md">
                    {filterSection()}
                </div>
                <div className="w-full fixed bottom-2 px-2 font-semibold  right-0 left-0 text-center md:hidden z-10">
                    <div className="p-2 bg-slate-900 text-white rounded-md cursor-pointer" onClick={() => setIsFiltersModalOpen(true)}>Filters</div>
                </div>
                <div className="flex-grow relative">
                    <div className="flex z-10 justify-between px-1 items-center bg-white border-2 rounded-lg overflow-hidden mx-auto sticky top-[80px]">
                        <input
                            type="text"
                            placeholder={`Search by ${displayedText}`}
                            className="px-4 py-2 outline-none w-[40%] sm:text-lg flex-grow"
                            value={searchKeyword}
                            onChange={e => setSearchKeyword(e.target.value)}
                        />
                    </div>
                    {isAcademyProfileListFetching ? (
                        <div className='grid grid-cols-1 gap-4 p-2 mt-[16px]'>
                            {Array.from({ length: 6 }).map((_, index) => (
                                <div
                                    key={index}
                                    className='p-2 rounded-md bg-slate-200 shadow-md shadow-slate-200 flex flex-col md:flex-row items-center md:gap-4 gap-1'
                                >
                                    <div className="w-full md:w-[440px] h-48 md:h-64 rounded-lg bg-slate-50 animate-pulse"></div>
                                    <div className='rounded-lg p-4 flex flex-col gap-2'>
                                        <Skeleton height={30} width={200} />
                                        <Skeleton height={20} width={150} />
                                        <Skeleton height={20} width={250} />
                                        <Skeleton className='flex items-center' height={30} width={80} />
                            
                                        <div className="flex items-center gap-x-4 gap-y-2 flex-wrap md:py-2 mt-2 text-sm">
                                            {Array.from({ length: 3 }).map((_, idx) => (
                                            <div key={idx} className="flex items-center gap-2 rounded-lg bg-slate-100 px-2 py-1">
                                                <Skeleton circle height={24} width={24} />
                                                <Skeleton width={80} height={20} />
                                            </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="flex flex-col gap-2 mt-6">
                            {academyProfiles?.map((academy) => (
                                <div 
                                    key={academy?.id}
                                    className='p-2 cursor-pointer rounded-md bg-slate-200 shadow-md shadow-slate-200 flex flex-col md:flex-row md:gap-4 gap-1'
                                    onClick={() => handleOpenAcademyProfile(academy?.id)}
                                >
                                    <img 
                                        src={academy?.attributes?.academy?.data?.attributes?.images?.data ? academy?.attributes?.academy?.data?.attributes?.images?.data[0]?.attributes?.url : ''}
                                        className='w-full md:w-[440px] flex-shrink-0 h-48 md:h-64 object-cover rounded-lg cursor-pointer'
                                    />
                                    <div className='rounded-lg p-4 cursor-pointer'>
                                        <div className='flex flex-col'>
                                            <div className='text-xl md:text-3xl font-semibold'>{academy?.attributes?.publicName}</div>
                                            <div className='text-gray-500 font-semibold text-sm line-clamp-3'>{academy?.attributes?.academy?.data?.attributes?.address}</div>
                                        </div>
                                        <div className="flex items-center mt-2">
                                            <div className='text-gray-500 font-semibold text-sm bg-green-400 p-1 px-2 rounded-lg flex gap-2 items-center'>
                                                {calculateAverageRating(academy?.attributes?.reviewRatings?.map(review => review?.rating) ?? [])?.toFixed(1) ?? 0.0}
                                                <img src='/assets/images/star-gray.png' className="h-4 w-4"/>
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-x-4 gap-y-2 flex-wrap md:py-2 mt-2 text-sm">
                                            {academy?.attributes?.academy?.data?.attributes?.ammenities?.split(',').includes('Certified coach') && (
                                                <div className="flex items-center gap-2 rounded-lg bg-slate-100 px-2 py-1">
                                                    <img src="/assets/images/coach.png" className="h-6 w-6" />
                                                    <div>Certified Coach</div>
                                                </div>
                                            )}
                                            {academy?.attributes?.academy?.data?.attributes?.ammenities?.split(',').includes('Parking') && (
                                                <div className="flex items-center gap-2 rounded-lg bg-slate-100 px-2 py-1">
                                                    <img src="/assets/images/parking.png" className="h-6 w-6" />
                                                    <div>Parking</div>
                                                </div>
                                            )}
                                            {academy?.attributes?.academy?.data?.attributes?.ammenities?.split(',').includes('Waiting-room') && (
                                                <div className="flex items-center gap-2 rounded-lg bg-slate-100 px-2 py-1">
                                                    <img src="/assets/images/lounge.png" className="h-6 w-6" />
                                                    <div>Waiting Room</div>
                                                </div>
                                            )}
                                            {academy?.attributes?.academy?.data?.attributes?.ammenities?.split(',').includes('Restroom') && (
                                                <div className="flex items-center gap-2 rounded-lg bg-slate-100 px-2 py-1">
                                                    <img src="/assets/images/toilet.png" className="h-6 w-6" />
                                                    <div>Restroom</div>
                                                </div>
                                            )}
                                            {academy?.attributes?.academy?.data?.attributes?.ammenities?.split(',').includes('CCTV') && (
                                                <div className="flex items-center gap-2 rounded-lg bg-slate-100 px-2 py-1">
                                                    <img src="/assets/images/cctv.png" className="h-6 w-6" />
                                                    <div>CCTV</div>
                                                </div>
                                            )}
                                            {academy?.attributes?.academy?.data?.attributes?.ammenities?.split(',').includes('Drinking water') && (
                                                <div className="flex items-center gap-2 rounded-lg bg-slate-100 px-2 py-1">
                                                    <img src="/assets/images/water.png" className="h-6 w-6" />
                                                    <div>Drinking water</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <Footer />
            <hr className="my-10 opacity-0 md:hidden"/>
            {isFiltersModalOpen && (
                <div className="fixed top-0 left-0 right-0 bottom-0 bg-[#00000080] w-screen h-screen overflow-y-auto z-[100] flex flex-col p-2">
                    <div className="flex-grow flex flex-col gap-2 overflow-y-auto p-2">
                        {filterSection()}
                    </div>
                    <div className="p-2 bg-slate-900 text-white rounded-md cursor-pointer text-center font-semibold" onClick={() => setIsFiltersModalOpen(false)}>
                        Close
                    </div>
                </div>
            )}
        </div>
    )
}

export default AcademiesListPage;