import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoChevronBack } from 'react-icons/io5';
import logo from '../../../assets/icons/link.svg';
import AssignmentPageViewModel from '../../view-models/assignment-viewmodel';
import { getFormattedDate } from '../../../utils/helpers/helpers';
import Layout from '../../components/common/layout';
import AssignmentListItem from '../../components/list-items/assignment-list-item';
import FullScreenLoader from '../../components/common/full-sreen-loader';
import { motion } from 'framer-motion';
import DragCloseDrawer from '../../components/common/drag-close-drawer';

const AssignmentPage = () => {
    const { 
        isLoading, 
        isSubmitFormOpen,
        handleToggleSubmitForm,
        useMediaQuery,
        handleOpenMoreInfo,
        isMoreInfoModalOpen,
        setIsMoreInfoModalOpen,
        regularAssignments,
        activeMoreInfoModalItem,
        handleDownloadFile,
        activeSubmissionFormModalItem,
        assignmentSubmissionData,
        handleTextSubmissionInpuChange,
        handleSubmissionFileUpload,
        assignmentSubmissions,
        handleAssignmentSubmission,
        showBadgeInfo,
        activeBadgeInfo,
        allBadges,
    } = AssignmentPageViewModel();

    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1);
    };

    return (
        <Layout isProfileSwitchTabVisible={(isMoreInfoModalOpen || activeBadgeInfo !== 0) ? false :true} hideMobileBottomBar={isMoreInfoModalOpen ? true : false}>
            {isLoading && <FullScreenLoader/>}
            <div className="flex p-2 justify-start flex-col">
                <div className="sm:block hidden">
                    <img src={logo} alt="logo" />
                </div>

                <div className={`gap-4 hidden md:flex pl-2 sm:pt-4 items-center`}>
                    <IoChevronBack
                        className="text-blue-500 text-3xl"
                        onClick={handleBackClick}
                    />
                    <span className="md:text-4xl text-2xl font-light sm:mt-1 ml-4">
                        Assignments
                    </span>
                </div>
                <hr className='my-2 border-2 shadow-md rounded-xl mx-2 hidden md:block'/>
            </div>
            <div className='flex w-full md:grid md:grid-cols-2 pb-[80px md:pb-0]'>
                <motion.div
                    className="h-full w-full flex-shrink-0 min-w-full md:max-h-[calc(100vh-191px)] overflow-y-auto thin-scrollbar"
                    animate={{
                        x: useMediaQuery('(max-width: 768px)')
                            ? (isSubmitFormOpen ? '-100%' : '0%')
                            : '0%'
                    }}
                    transition={{ type: 'spring', stiffness: 100 }}
                >
                    <span className="sm:text-4xl text-2xl font-light sm:mt-1 ml-4 md:hidden">
                        Assignments
                    </span>
                    <hr className='my-2 border-2 shadow-md rounded-xl mx-2 md:hidden'/>

                    <div className="flex items-center justify-center gap-4 pt-4">
                        <div className="w-full p-4 md:p-0 flex flex-col md:flex-row md:flex-wrap gap-8">
                            {regularAssignments?.map(assignment => (
                                <AssignmentListItem 
                                    attributes={assignment?.attributes}
                                    submissionType={assignment?.attributes?.submissionFileType}
                                    onSubmitClick={(e) => handleToggleSubmitForm(e, assignment?.id)}
                                    openMoreInfo={() => handleOpenMoreInfo(assignment?.id)}
                                    isSelectedForSubmission={activeSubmissionFormModalItem?.id === assignment?.id}
                                    isSubmitted={assignmentSubmissions?.some(submission => submission.attributes?.assignment.data?.id === assignment?.id)}
                                    badges={assignmentSubmissions?.find(submission => submission.attributes?.assignment.data?.id === assignment?.id)?.attributes?.badges?.data ?? []}
                                    showBadgeInfo={showBadgeInfo}
                                />
                            ))}
                        </div>
                    </div>
                </motion.div>
                <motion.div
                    className="h-full w-full flex-shrink-0 min-w-full md:border-l-2 md:p-2 md:border-gray-200 rounded md:max-h-[calc(100vh-191px)] overflow-y-auto thin-scrollbar"
                    initial={{ x: '100%' }}
                    animate={{
                        x: useMediaQuery('(max-width: 768px)')
                            ? (isSubmitFormOpen ? '-100%' : '100%')
                            : '0%'
                    }}
                    transition={{ type: 'spring', stiffness: 100, mass: 0.5 }}
                >
                    {isSubmitFormOpen ? (
                        <div className="p-4">
                            <div className='flex items-center gap-3'>
                                <div className='text-3xl font-semibold'>{activeSubmissionFormModalItem?.attributes?.name}</div>
                                <div 
                                    className='p-1 text-xs px-2 border-2 border-black font-semibold active:scale-105 transition-all rounded-lg'
                                    onClick={() => handleOpenMoreInfo(activeSubmissionFormModalItem?.id ?? 0)}
                                >
                                    View Info
                                </div>
                            </div>

                            {activeSubmissionFormModalItem?.attributes?.submissionFileType === 'TEXT' && (
                                <textarea 
                                    className='w-full shadow-inner shadow-gray-400 p-2 rounded mt-2 focus:outline-0 disabled:bg-slate-200'
                                    rows={8}
                                    value={assignmentSubmissionData.text}
                                    onChange={(e) => handleTextSubmissionInpuChange(e)}
                                    disabled={assignmentSubmissions?.some(submission => submission?.attributes?.assignment?.data?.id === activeSubmissionFormModalItem?.id)}
                                />
                            )}
                            {activeSubmissionFormModalItem?.attributes?.submissionFileType !== 'TEXT' && (
                                <>
                                    {!assignmentSubmissions?.some(submission => submission?.attributes?.assignment?.data?.id === activeSubmissionFormModalItem?.id) && (
                                        <div className='border-2 border-dashed border-[#39B5F9] p-2 rounded mt-2 bg-blue-50 flex gap-4 items-center cursor-pointer relative'>
                                            <div className='flex justify-center items-center bg-[#39B5F9] h-12 w-12 min-h-12 min-w-12 rounded-full'>
                                                <img 
                                                    src='/assets/images/attachment.png'
                                                    className='h-6 w-6'
                                                />
                                            </div>
                                            <div>
                                                <div className='font-semibold text-xl'>Attachments</div>
                                                <div className='text-slate-500 text-sm'>
                                                    {   
                                                        `Select a ${activeSubmissionFormModalItem?.attributes?.submissionFileType === 'IMAGE' ? 'image' 
                                                        : activeSubmissionFormModalItem?.attributes?.submissionFileType === 'DOCUMENT' ? 'PDF' 
                                                        : activeSubmissionFormModalItem?.attributes?.submissionFileType === 'VIDEO' ? 'video' 
                                                        : activeSubmissionFormModalItem?.attributes?.submissionFileType === 'AUDIO' ? 'audio' 
                                                        : 'text'} file, File size should not exceed 10 MB.`
                                                    }
                                                </div>
                                            </div>
                                            <input 
                                                type='file'
                                                className='opacity-0 h-full w-full absolute' 
                                                accept={activeSubmissionFormModalItem?.attributes?.submissionFileType === 'IMAGE' ? 'image/*'
                                                    : activeSubmissionFormModalItem?.attributes?.submissionFileType === 'DOCUMENT' ? 'application/pdf' 
                                                    : activeSubmissionFormModalItem?.attributes?.submissionFileType === 'VIDEO' ? 'video/*' 
                                                    : activeSubmissionFormModalItem?.attributes?.submissionFileType === 'AUDIO' ? 'audio/*' : ''
                                                }
                                                onChange={handleSubmissionFileUpload}
                                            />
                                        </div>
                                    )}
                                    <div className='mt-8'>
                                        <div className='rounded'>
                                            {activeSubmissionFormModalItem?.attributes?.submissionFileType === 'IMAGE' && (
                                                assignmentSubmissionData?.fileId > 0 ? (
                                                    <div className='h-[180px] rounded bg-gray-100'>
                                                        <img 
                                                            src={assignmentSubmissionData?.fileUrl}
                                                            alt="image" 
                                                            className='w-full h-full object-contain' 
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className='h-[180px] rounded bg-gray-100 w-full animate-pulse'>
                                                    </div>
                                                )
                                            )
                                            }
                                            {activeSubmissionFormModalItem?.attributes?.submissionFileType === 'VIDEO' && (
                                                assignmentSubmissionData?.fileId > 0 ? (
                                                    <div className='h-[240px] rounded bg-gray-100'>
                                                        <video 
                                                            className='w-full h-full object-contain' 
                                                            controls
                                                        >
                                                            <source 
                                                                src={assignmentSubmissionData?.fileUrl}
                                                                type='video/mp4'
                                                            />
                                                            Not support by device.
                                                        </video>
                                                    </div>
                                                ) : (
                                                    <div className='h-[240px] rounded bg-gray-100 w-full animate-pulse'>
                                                    </div>
                                                )
                                            )}
                                            {activeSubmissionFormModalItem?.attributes?.submissionFileType === 'AUDIO' && (
                                                assignmentSubmissionData?.fileId > 0 ? (
                                                    <div className='rounded bg-gray-100'>
                                                        <audio controls className='w-full bg-[#F1F3F4] rounded'>
                                                            <source 
                                                                src={assignmentSubmissionData?.fileUrl}
                                                                type='audio/mp3'
                                                            />
                                                            Not support by device.
                                                        </audio>
                                                    </div>
                                                ) : (
                                                    <div className='h-[54px] rounded bg-gray-100 w-full animate-pulse'>
                                                    </div>
                                                )
                                            )}
                                            {activeSubmissionFormModalItem?.attributes?.submissionFileType === 'DOCUMENT' && (
                                                assignmentSubmissionData?.fileId > 0 ? (
                                                    <div className='rounded bg-gray-100 flex items-center'>
                                                        <iframe 
                                                            src={assignmentSubmissionData?.fileUrl}
                                                            width="100%" 
                                                            height="600px"
                                                        >
                                                            Device does not support this feature.
                                                        </iframe>
                                                    </div>
                                                ) : (
                                                    <div className='h-[600px] rounded bg-gray-100 w-full animate-pulse'>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                            {assignmentSubmissions?.some(submission => submission?.attributes?.assignment?.data?.id === activeSubmissionFormModalItem?.id) ? (
                                <div 
                                    className='mt-4 px-4 py-2 rounded border-2 border-[#39B5F9] text-[#39B5F9] font-semibold text-center'
                                >
                                    Submitted on {getFormattedDate(new Date(assignmentSubmissions?.find(submission => submission?.attributes?.assignment?.data?.id === activeSubmissionFormModalItem?.id)?.attributes?.createdAt ?? ''))}
                                </div>
                            ):(
                                <div 
                                    className={`mt-4 px-4 py-2 rounded border-4 border-[#39B5F9] text-[#39B5F9] font-semibold text-center active:bg-green-200 transition-all ${(assignmentSubmissionData.fileId > 0 || assignmentSubmissionData?.text !== '') ? 'active:scale-90 cursor-pointer' : 'opacity-40 cursor-not-allowed'}`}
                                    onClick= {() => {
                                        if((assignmentSubmissionData.fileId > 0 || assignmentSubmissionData?.text !== '')){
                                            handleAssignmentSubmission();
                                        };
                                    }}
                                >
                                    Submit Assignment
                                </div>
                            )}
                            <div 
                                onClick={(e) => handleToggleSubmitForm(e, 0)} 
                                className='md:hidden mt-4 px-4 py-2 rounded bg-[#39B5F9] font-semibold text-white text-center cursor-pointer'
                            >
                                Go Back
                            </div>
                        </div>
                    ) : (
                        <div className='p-4'>
                            <div className='h-12 w-[260px] bg-zinc-300 rounded animate-pulse'></div>
                            <div className='h-6 mt-2 w-full bg-slate-200 rounded animate-pulse'></div>
                            <div className='h-6 mt-2 w-full bg-slate-200 rounded animate-pulse'></div>
                            <div className='h-6 mt-2 w-[320px] bg-slate-200 rounded animate-pulse'></div>
                            <div className='h-[180px] mt-6 w-full bg-slate-300 rounded animate-pulse'></div>
                            <div className='h-14 mt-8 w-full bg-slate-200 rounded animate-pulse'></div>
                            <div className='h-14 mt-2 w-full bg-slate-200 rounded animate-pulse'></div>
                        </div>
                    )}
                </motion.div>
            </div>
            <DragCloseDrawer open={isMoreInfoModalOpen} setOpen={setIsMoreInfoModalOpen}>
                <div className='w-full h-full flex md:justify-center'>
                    <div className='flex flex-col w-full h-full md:w-[700px]'>
                        <div className='text-3xl text-center text-white font-semibold'>{activeMoreInfoModalItem?.attributes?.name}</div>
                        <hr className='my-2 border-2 shadow-md rounded-xl border-slate-500' />
                        <div className='text-sm text-slate-300 p-2 border-2 rounded-lg border-slate-800 h-[220px] min-h-[160px] overflow-y-auto thin-scrollbar bg-gray-900 my-2'>
                            {activeMoreInfoModalItem?.attributes?.description}
                        </div>
                        {activeMoreInfoModalItem?.attributes?.file?.data && (
                            <div className='border border-gray-900 shadow-sm shadow-gray-700 rounded'>
                                {activeMoreInfoModalItem?.attributes?.file?.data?.attributes?.mime.startsWith('image') &&
                                    <div className='h-[180px] rounded bg-gray-700'>
                                        <img 
                                            src={activeMoreInfoModalItem?.attributes?.file?.data?.attributes?.url} 
                                            alt="image" 
                                            className='w-full h-full object-contain' 
                                        />
                                    </div>
                                }
                                {activeMoreInfoModalItem?.attributes?.file?.data?.attributes?.mime.startsWith('video') &&
                                    <div className='h-[240px] rounded bg-gray-700'>
                                        <video 
                                            className='w-full h-full object-contain' 
                                            controls
                                        >
                                            <source 
                                                src={activeMoreInfoModalItem?.attributes?.file?.data?.attributes?.url} 
                                                type={activeMoreInfoModalItem?.attributes?.file?.data?.attributes?.mime}
                                            />
                                            Not support by device.
                                        </video>
                                    </div>
                                }
                                {activeMoreInfoModalItem?.attributes?.file?.data?.attributes?.mime.startsWith('audio') &&
                                    <div className='rounded bg-gray-700'>
                                        <audio controls className='w-full bg-[#F1F3F4] rounded'>
                                            <source 
                                                src={activeMoreInfoModalItem?.attributes?.file?.data?.attributes?.url} 
                                                type={activeMoreInfoModalItem?.attributes?.file?.data?.attributes?.mime}
                                            />
                                            Not support by device.
                                        </audio>
                                    </div>
                                }
                                {activeMoreInfoModalItem?.attributes?.file?.data?.attributes?.mime === 'application/pdf' &&
                                    <div className='rounded bg-gray-700 flex items-center'>
                                        {/* <embed src={activeMoreInfoModalItem?.attributes?.file?.data?.attributes?.url} type="application/pdf" width="100%" height="600px"/> */}
                                        <iframe src={activeMoreInfoModalItem?.attributes?.file?.data?.attributes?.url} width="100%" height="600px"></iframe>
                                    </div>
                                }
                                {(
                                    !activeMoreInfoModalItem?.attributes?.file?.data?.attributes?.mime.startsWith('audio') &&
                                    !activeMoreInfoModalItem?.attributes?.file?.data?.attributes?.mime.startsWith('video') &&
                                    !(activeMoreInfoModalItem?.attributes?.file?.data?.attributes?.mime === 'application/pdf')
                                ) &&
                                    <div className='flex items-center justify-end gap-6 p-2'>
                                        <img
                                            src='/assets/images/maximize.png'
                                            alt="maximize"
                                            className='h-5 w-5 active:scale-95 transition-all cursor-pointer'
                                        />
                                        <img
                                            src='/assets/images/download-two.png'
                                            alt="download"
                                            onClick={() => handleDownloadFile(activeMoreInfoModalItem?.attributes?.file?.data?.attributes?.url ?? '', activeMoreInfoModalItem?.attributes?.name ?? '')}
                                            className='h-5 w-5 active:scale-95 transition-all cursor-pointer'
                                        />
                                    </div>
                                }
                            </div>
                        )}
                        {activeMoreInfoModalItem?.attributes?.acceptSubmission && (
                            assignmentSubmissions?.some((submission) => submission?.attributes?.assignment?.data?.id === activeMoreInfoModalItem?.id) ? (
                                <div className='flex flex-col justify-end flex-grow gap-2 mt-4'>
                                    <div 
                                        className='md:hidden mt-4 px-4 py-2 rounded border-2 border-[#39B5F9] text-[#39B5F9] font-semibold text-center'
                                    >
                                        Submitted on {getFormattedDate(new Date(assignmentSubmissions?.find(submission => submission?.attributes?.assignment?.data?.id === activeMoreInfoModalItem?.id)?.attributes?.createdAt ?? ''))}
                                    </div>
                                </div>
                            ) : (
                                <div className='flex flex-col justify-end flex-grow gap-2 mt-4'>
                                    <div className='text-xl text-center text-white font-semibold rounded border-2 border-gray-800 py-2'>
                                        Deadline: {getFormattedDate(new Date(activeMoreInfoModalItem?.attributes?.submissionDeadline ?? ''))}
                                    </div>
                                    {!isSubmitFormOpen &&
                                        <div 
                                            className='text-2xl text-center w-full text-white font-semibold bg-[#39B5F9] rounded py-2 active:scale-105 transition-all'
                                            onClick={(e) => {
                                                setIsMoreInfoModalOpen(false);
                                                setTimeout(() => {
                                                    handleToggleSubmitForm(e, activeMoreInfoModalItem?.id);
                                                }, 100);
                                            }}
                                        >
                                            Submit
                                        </div>
                                    }
                                </div>
                            )
                        )}
                    </div>
                </div>
            </DragCloseDrawer>
            {activeBadgeInfo !== 0 && (
                <div 
                    onClick={() => showBadgeInfo(0)}
                    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 backdrop-blur-lg"
                >
                    <motion.div
                        className="bg-transparent p-8"
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.8 }}
                        transition={{ duration: 0.3 }}
                    >
                        <div className='text-[#FFD700] text-[2.5rem] text-center font-semibold'>{allBadges?.find((badge) => badge?.id === activeBadgeInfo)?.attributes?.name} Badge</div>
                        <div className="relative">
                            <img
                                src={allBadges?.find((badge) => badge?.id === activeBadgeInfo)?.attributes?.badgeImage?.data?.attributes?.url}
                                alt="Sparkling"
                                className="w-full h-full rounded-lg drop-shadow-lg"
                            />
                        </div>
                        <div className='text-[#ffd900e2] text-xl text-center font-semibold'>{allBadges?.find((badge) => badge?.id === activeBadgeInfo)?.attributes?.description}</div>
                    </motion.div>
                </div>
            )}
        </Layout>
    );
};

export default AssignmentPage;
