import React from 'react'
import NavbarStatic from '../../components/navbar-static-pages'
import BlogsListViewModel from '../../view-models/blog-list-viewmodel';
import { Routes } from '../../navigation/routes';
import Footer from '../../components/landing-page-items/footer';
import Navbar from '../../components/landing-page-items/navbar';
import { Helmet } from 'react-helmet-async';
import Skeleton from 'react-loading-skeleton';

const BlogsListPage = () => {
    const {
        isLoading,
        blogs,
        handleOpenBlogPost,
    } = BlogsListViewModel();

    return (
        <div className='h-screen flex flex-col'>
            <Helmet>
                <title>SpArts Blogs | Insights on Sports, Arts, and Student Growth</title>
                <meta name='description' content='Explore the SpArts Blog for expert tips, insights, and updates on sports, creative arts, and student development. Stay informed on how technology enhances learning, training, and personal growth through SpArts AI and more.'/>
            </Helmet>
            <Navbar />
            {isLoading ? (
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-6 md:px-16 mt-[88px]'>
                {Array.from({ length: 6 }).map((_, index) => (
                    <div 
                        key={index} 
                        className='p-4 rounded-md bg-slate-50 shadow-md shadow-slate-200'
                    >
                        <Skeleton height={256} className='w-full rounded-lg' />
                        
                        <div className='rounded-lg p-4'>
                            <div className='flex flex-col gap-2'>
                                <Skeleton height={30} width='60%' />
                                <Skeleton count={3} className='text-gray-500 text-sm' />
                            </div>
                        </div>
                    </div>
                ))}
                </div>
            ) : (
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-6 md:px-16 mt-[88px]'>
                    {blogs.map((blog) => (
                        <div 
                            key={blog?.id}
                            className='p-4 cursor-pointer rounded-md bg-slate-50 shadow-md shadow-slate-200'
                            onClick={() => handleOpenBlogPost(blog?.id)}
                        >
                            <img 
                                src={blog?.attributes?.previewImage?.data?.attributes?.url}
                                className='w-full h-64 object-cover rounded-lg cursor-pointer'
                            />
                            <div className=' rounded-lg p-4 cursor-pointer'>
                                <div className='flex flex-col'>
                                    <div className='text-xl font-semibold'>{blog?.attributes?.title}</div>
                                    <div className='text-gray-500 font-semibold text-sm line-clamp-3'>{blog?.attributes?.description}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <Footer />
        </div>
    )
}

export default BlogsListPage